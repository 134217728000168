import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { AuthProvider } from "./contexts/AuthContext";
import { LightModeProvider } from "./contexts/MainContext";
import "bootstrap/dist/css/bootstrap.min.css";
import App from "./App";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { ReactQueryDevtools } from "react-query/devtools";
import { ThemeProvider } from '@mui/material/styles';
import theme from "./themes";

const root = ReactDOM.createRoot(document.getElementById("root"));
const queryClient = new QueryClient();



root.render(
  <React.StrictMode>
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <LightModeProvider>
              {/* <ThemeProvider theme={theme}> */}
              <App />
              {/* </ThemeProvider> */}
            </LightModeProvider>
          </AuthProvider>
          {/* <ReactQueryDevtools initialIsOpen={false} /> */}
        </QueryClientProvider>
      </BrowserRouter>
    </LocalizationProvider>
  </React.StrictMode>
);
