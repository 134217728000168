import React from 'react'
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import GroupIcon from "@mui/icons-material/Group";

const moviesLeaderboardWatchlistTable = ({
  movie,
  idx,
  handleInfoClick,
  genres,
  find,
  user,
  isGlobal
}) => {
  return (
      <div key={movie.id} onClick={() => handleInfoClick(movie?.movie_id)}>
          <div className="leaderboard-row d-flex">
              <div className="leaderboard-cell id-cell leader-cell">
                  {idx + 1}{" "}
                  {!isGlobal && (
                      <>
                          {(genres || find) && user && movie?.global_rank && (
                              <>({movie?.global_rank})</>
                          )}
                      </>
                  )}
              </div>
              <div className="leaderboard-cell movie-cell leader-cell">
                  {idx <= 9 && <img src={movie.image} alt={movie.title} />}

                  <div>
                      <h6 className="m-0 p-0 leader-h5">
                          {movie.title}
                          {idx + 1 === 1 && (
                              <EmojiEventsIcon className="gold-color" />
                          )}
                          {idx + 1 === 2 && (
                              <EmojiEventsIcon className="silver-color" />
                          )}
                          {idx + 1 === 3 && (
                              <EmojiEventsIcon className="bronze-color" />
                          )}
                      </h6>
                      <p className="m-0 p-0 text-muted leader-p">
                          {movie.description}
                      </p>
                  </div>
              </div>
              <div
                  className="leaderboard-cell score-cell leader-cell"
                  style={isGlobal &&{flexDirection:'column'}}
              >
                  {movie.score}
                  {isGlobal && (
                      <>
                          <br />
                          <span style={{fontSize:'14px'}}>
                              {movie?.users_voted} <GroupIcon sx={{ fontSize: 20 }} /> 
                          </span>
                      </>
                  )}
              </div>
          </div>
      </div>
  );
};

export default moviesLeaderboardWatchlistTable