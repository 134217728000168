import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from "@mui/material";
import React from 'react';
import {
    useGenresList,
    useLeaderboard,
    useMovieDetails,
    useGlobalLeaderboard,
} from "../../hooks/apis/movies";

import { useAuth } from "../../contexts/AuthContext";
import NoData from '../common/noData';
import DataTable from './moviesLeaderboardWatchlistTable';
import {getLocalstorageWithExpiration} from './../../utils'
import MovieModal from './movieModal'

export default function Index({isGlobal , username}) {
  const { isLoggedIn, user } = useAuth();

  const { data: genresList } = useGenresList();
  const [type, setType] = React.useState("movie");
  const [find, setFind] = React.useState("");
  const [genres, setGenres] = React.useState(null);

  const leaderboardJSON = getLocalstorageWithExpiration("leaderboard");
  const leaderboard = leaderboardJSON
    ? JSON.parse(leaderboardJSON)
        .filter((movie) => {
          const titleMatch = movie.title
            .toLowerCase()
            .includes(find?.toLowerCase());

          const genresMatch =
            genres === null ||
            movie?.genres?.some((genre) => genre.param === genres);

          const typeMatch =
            movie.type === (type == "movie" ? "movie" : "tv-show");

          return titleMatch && genresMatch && typeMatch;
        })
        .sort((a, b) => parseInt(b.score) - parseInt(a.score))
    : [];

  const handleChange = (event) => {
    setGenres(event.target.value);
  };

  const handleFindChange = (event) => {
    setFind(event.target.value);
  };


  const { isFetching, data } = useLeaderboard(
    {
      type: type,
      find: find,
      genres: genres,
      username: username,
    },
    isLoggedIn
  );

  
  const {data: globalLeaderboardData} = useGlobalLeaderboard({
      type: type,
      find: find,
      genres: genres,
  });

  

  // Movie Info Modal
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [movieID, setMovieID] = React.useState(null);
  const {
    isFetching: isFetchingMovie,
    data: movieData,
  } = useMovieDetails(movieID , type);
  const handleInfoClick = (id) => {
    setMovieID(id);
    handleOpen();
  };


  return (
    <>
      <div className="container">
        <div
          className={`leader-board-filter d-flex justify-content-between ${!username && 'mb-3'}`}
        >
          <div className="d-flex">
            <div>
              {!username && (
                <h3 className="text-light text-uppercase m-0 p-0">
                  {isGlobal ? "Global Leaderboard" : "Leaderboard"}
                </h3>
              )}
            </div>
          </div>
          {!username && (
            <div className="d-flex">
              <FormControl className="custom_field">
                <InputLabel id="demo-simple-select-label">
                  Filter by genres
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  size={"small"}
                  id="demo-simple-select"
                  value={genres}
                  onChange={handleChange}
                >
                  {genres && (
                    <MenuItem key={0} value={null} className="text-danger">
                      Clear Filter
                    </MenuItem>
                  )}

                  {genresList?.map((option) => (
                    <MenuItem key={option.param} value={option.param}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <div className="mx-1"> </div>
              <TextField
                className="custom_field"
                size={"small"}
                label="Search ..."
                variant="outlined"
                value={find}
                onChange={handleFindChange}
              />
            </div>
          )}
        </div>

        <div className="leaderboard-tabs d-flex">
          <button
            className={`px-4 ${type === "movie" && "active"}`}
            onClick={() => setType("movie")}
          >
            Movies
          </button>
          <button
            className={`px-4 ${type === "tv-show" && "active"}`}
            onClick={() => setType("tv-show")}
          >
            Tv Shows
          </button>
        </div>

        <div className="leaderboard-table mb-4">
          {(isGlobal
            ? globalLeaderboardData?.results
            : (user && data?.results) || leaderboard
          )?.map((movie, idx) => (
            <DataTable
              movie={movie}
              idx={idx}
              handleInfoClick={handleInfoClick}
              genres={genres}
              find={find}
              user={user}
              isGlobal={isGlobal}
            />
          ))}
          {!data?.count && !isFetching && !isGlobal && <NoData />}
          {isFetching && !isGlobal && <NoData text="Loading..." />}
        </div>
      </div>

      <MovieModal
        handleClose={handleClose}
        isFetchingMovie={isFetchingMovie}
        movieData={movieData}
        open={open}
      />
    </>
  );
};