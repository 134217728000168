import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import NoData from "./noData";


const moviesSwiper = ({ list }) => {
  return list?.length > 0 ? (
    <>
      <Swiper
        spaceBetween={0}
        slidesPerView={1}
        modules={[Autoplay, Navigation]}
        navigation
        autoplay={{
          delay: 4000,
        }}
        loop={true}
        // onSlideChange={() => console.log("slide change")}
        // onSwiper={(swiper) => console.log(swiper)}
      >
        {list?.map((item, idx) => (
          <SwiperSlide>
            <div className="d-flex flex-column align-items-center">
              <img
                className="profile-top-image"
                src={item?.image}
                alt={item?.title}
              />
              <h1 className="profile-top-counter">{idx + 1}</h1>
            </div>

            <p className="text-center">Score : {item?.score}</p>
            <h4 className="text-center">{item?.title}</h4>
            {/* <p className="text-faded2 text-center max-3-lines">
              {item?.description}
            </p> */}
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  ) : (
    <NoData />
  );
};

export default moviesSwiper;
