import React from "react";
import NoData from "./noData";
import { usePlaylists, useDeletePlaylist ,useTogglePlaylistSubscribe} from "../../hooks/apis/movies";
import CreateModal from "./createModal";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import Chip from "@mui/material/Chip";

export default function Index({ is_create, username }) {
  const [playlistID, setPlaylistID] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [type, setType] = React.useState("movie");
  const { data } = usePlaylists({
    type,
    username,
  });

  const [formData, setFormData] = React.useState({
    name: null,
    is_public: true,
    movies: [],
    genres: [],
    type: type,
  });

  const handleResetFormData = () => {
    setFormData({
      ...formData,
      name: null,
      is_public: true,
      movies: [],
      genres: [],
    });
  };


  const { mutate: deletePlaylistMutate } = useDeletePlaylist();
  const { mutate: togglePlaylistSubscribe } = useTogglePlaylistSubscribe();

  return (
    <>
      <section className="leaderboard-section">
        <div className="container">
          <div className="leaderboard-tabs d-flex justify-content-between">
            <div className="d-flex">
              <button
                className={`px-4 ${type === "movie" && "active"}`}
                onClick={() => setType("movie")}
              >
                Movies
              </button>
              <button
                className={`px-4 ${type === "tv-show" && "active"}`}
                onClick={() => setType("tv-show")}
              >
                Tv Shows
              </button>
            </div>
            {is_create && (
              <button
                className={`px-4 ${type === "tv-show" && "active"}`}
                onClick={() => {
                  handleResetFormData();
                  setPlaylistID(null);
                  handleOpen();
                }}
              >
                Create
              </button>
            )}
          </div>

          <div className="leaderboard-table mb-4">
            {data?.map((playlist, idx) => (
              <>
                <div key={playlist.id}>
                  <div className="leaderboard-row d-flex">
                    <div className="leaderboard-cell id-cell leader-cell">
                      {idx + 1}
                    </div>
                    <div className="leaderboard-cell movie-cell leader-cell p-3">
                      {/* {idx <= 9 && <img src={movie.image} alt={movie.title} />} */}

                      <div>
                        <h6 className="m-0 p-0 leader-h5">{playlist.name}</h6>
                        <p className="p-0 m-0">
                          {playlist.get_movies_count} Movies
                        </p>
                        {playlist?.is_public ? (
                          <Chip
                            label="Public"
                            size="small"
                            variant="outlined"
                            color="success"
                          />
                        ) : (
                          <Chip
                            label="Private"
                            size="small"
                            variant="outlined"
                            color="warning"
                          />
                        )}
                      </div>
                    </div>
                    <div className="leaderboard-cell score-cell leader-cell">
                      {playlist.is_owner ? (
                        <>
                          <IconButton
                            aria-label="edit"
                            onClick={() => {
                              setPlaylistID(playlist?.id);
                              handleOpen();
                            }}
                          >
                            <DriveFileRenameOutlineIcon />
                          </IconButton>

                          <IconButton
                            aria-label="delete"
                            onClick={() => deletePlaylistMutate(playlist?.id)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </>
                      ) : (
                        <h6
                          onClick={() =>
                            togglePlaylistSubscribe({
                              playlist_id: playlist?.id,
                            })
                          }
                        >
                          {!playlist?.is_subscribed
                            ? "Subscribe"
                            : "Unsubscribe"}
                        </h6>
                      )}
                    </div>
                  </div>
                </div>
              </>
            ))}

            {data?.length === 0 && <NoData />}
          </div>
        </div>
      </section>

      <CreateModal
        handleClose={handleClose}
        open={open}
        type={type}
        playlistID={playlistID}
        formData={formData}
        setFormData={setFormData}
        handleResetFormData={handleResetFormData}
      />
    </>
  );
}
