import * as React from "react";
import { UilStar, UilFilter } from "@iconscout/react-unicons";
import SearchModal from "./searchModal";
import FilterModal from "./filterModal";
import Button from "@mui/material/Button";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import FilterAltIcon from "@mui/icons-material/FilterAlt";



function Nav({ open, setOpen, filterOpen, setFilterOpen }) {
    const { isLoggedIn } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const handleDashboard = () => {
        if (location?.pathname == "/tv-shows") {
            navigate("/leaderboard", { state: "tv-show" });
        } else {
            navigate("/leaderboard", { state: "movie" });
        }
    };

    return (
      <>
        <div className="nav-row">
          <div className="custom-container">
            <Button onClick={handleDashboard}>
              <UilStar className="text-light" />
            </Button>

            <SearchModal open={open} setOpen={setOpen} />

            {!isLoggedIn ? (
              <Button disabled>
                <FilterAltIcon className="text-light" />
              </Button>
            ) : (
              <FilterModal
                filterOpen={filterOpen}
                setFilterOpen={setFilterOpen}
              />
            )}
          </div>
        </div>
      </>
    );
}

export default Nav;
