import LeaderboardTable from '../common/leaderboardTable';
import {
  useProfile,
  useUpdateProfile,
  useCountries,
  useChangePassword,
} from "../../hooks/apis/Auth";
import { TextField, Button, CircularProgress } from "@mui/material";
import AvatarImage from '../../assets/images/avatar.png'
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import Box from "@mui/material/Box";
import FavoriteIcon from "@mui/icons-material/Favorite";
import CloseIcon from "@mui/icons-material/Close";

import * as React from "react";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#fff",
  border: "2px solid #eee",
  borderRadius: "10px",
  boxShadow: 24,
  pt: 4,
  px: 4,
  pb: 4,
  width: "40%",
  textAlign: "center",
  maxHeight: "80vh",
  overflow: "auto",
};


export default function Index() {

  const [isChangePasswordModalopen, setChangePasswordModalopen] = React.useState(false);
  const handleChangePasswordModalOpen = () => setChangePasswordModalopen(true);
  const handleChangePasswordModalClose = () => setChangePasswordModalopen(false);

  const { isLoading: isProfileLoading, data: profile } = useProfile();
  const { mutate, isLoading } = useUpdateProfile();
  const { mutate:changepasswordMutate, isLoading:changingpassword } = useChangePassword();
  const { isFetching, data:countries, refetch } = useCountries();
  const [formData, setFormData] = React.useState({
    name: "",
    bio: "",
    avatar: null, // Initialize "avatar" as null
    new_password: null,
    current_password: null,
  });

  const [isEdit, setIsEdit] = React.useState(false);
  const GENDER_CHOICES = [
    { value: "1", label: "Male" },
    { value: "2", label: "Female" },
    { value: "3", label: "Other" },
  ];



  React.useEffect(() => {
    // Populate the form data with the fetched profile when it becomes available
    if (!isProfileLoading && profile) {
      setFormData({
        name: profile.name || "", // Provide default values or empty strings if not available
        bio: profile.bio || "",
        date_of_birth: profile.date_of_birth || null,
        country: profile.country.code || null,
        gender: profile.gender || null,
        avatar: null, // You can update this if you have a URL for the avatar
      });
    }
  }, [isProfileLoading, profile]);


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData({
      ...formData,
      avatar: file,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    mutate(formData)
  };

  const handleSubmitChangePassword = (e) => {
    e.preventDefault();
    changepasswordMutate(formData);
  };



  return (
    <>
      <section className="home-section p-4">
        <div className="row w-100">
          <div className="col-12 h-100">
            <section className="home-leaderboard-section">
              <LeaderboardTable />
            </section>
          </div>
        </div>
      </section>

      <Modal
        open={isChangePasswordModalopen}
        onClose={handleChangePasswordModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="d-flex justify-content-between">
            <h3 className="d-flex justify-content-start mb-4">
              Change Password
            </h3>
            <h1 className="d-flex justify-content-end mb-4">
              <CloseIcon
                style={{ cursor: "pointer" }}
                onClick={handleChangePasswordModalClose}
              />
            </h1>
          </div>

          <form onSubmit={handleSubmitChangePassword}>
            <input
              className="form-select w-100 profile-edit-input"
              type="text"
              id="current_password"
              name="current_password"
              value={formData.current_password}
              placeholder="Current Password"
              onChange={handleInputChange}
            />
            <input
              className="form-select w-100 profile-edit-input"
              type="text"
              id="new_password"
              name="new_password"
              value={formData.new_password}
              placeholder="New Password"
              onChange={handleInputChange}
            />
            <div className="profile-btn2">
              <Button type="submit" variant="outlined" size="medium">
                Change Password
              </Button>
            </div>
          </form>
        </Box>
      </Modal>
    </>
  );
}

