import React, { createContext, useState, useContext, useEffect } from "react";
import { toast } from "react-toastify";
import {
  setLocalstorageWithLongExpiration,
  getLocalstorageWithExpiration,
} from "../utils";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const token = getLocalstorageWithExpiration("token");
    if (token) {
      validateToken(token);
    }
  }, []);


  // Function to validate the token
  const validateToken = async (token) => {
    try {
        
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/auth/users/me/`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Pass token in Authorization header
          },
        }
      );

      if (response.status === 200) {
        setIsLoggedIn(true);
        setUser(await response.json());
      } else {
        logout();
        setUser(null);
      }
    } catch (error) {
      console.error("Token validation error:", error);
      logout();
    }
  };



  // Function to log in
  const login = (data) => {
    setLocalstorageWithLongExpiration("token", data.token);
    setLocalstorageWithLongExpiration("user", JSON.stringify(data));
    toast.success("Logged in successfully ...");
    setIsLoggedIn(true);
  };

  // Function to log out
  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("selected_search");
    localStorage.removeItem("leaderboard");
    setIsLoggedIn(false);
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn,user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
