import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useLogin } from "../../hooks/apis/Auth";
import { useAuth } from "../../contexts/AuthContext";
import './style.css'

export default function LoginPage() {
  const navigate = useNavigate();
  const { isLoggedIn, login } = useAuth();
  const [formData, setFormData] = useState({ email: "", password: "" });
  const { mutate, isLoading } = useLogin();


  useEffect(() => {
    if (isLoggedIn) {
      navigate("/"); // Redirect to homepage if already logged in
    }
  }, [isLoggedIn, navigate]);


  const handleSubmit = async (event) => {
    event.preventDefault();
    mutate(formData, {
      onSuccess: () => {
        // Navigate to the previous page or dashboard after login
        navigate('/');
      },
    });
  };


  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <section id="login-page">
      <div className="login-page-overlay"></div>
      <div className="login">
        <form onSubmit={handleSubmit}>
          <div className="form">
            <h2>Hello User</h2>
            <div className="form-field">
              {/* <label htmlFor="login-mail">
                <i className="fa fa-user" />
              </label> */}
              <input
                id="login-mail"
                type="text"
                name="email"
                placeholder="Email Address"
                value={formData.email}
                onChange={handleChange}
                required="true"
              />
              {/* <svg>
                <use href="#svg-check" />
              </svg> */}
            </div>
            <div className="form-field">
              {/* <label htmlFor="login-password">
                <i className="fa fa-lock" />
              </label> */}
              <input
                id="login-password"
                type="password"
                name="password"
                placeholder="Password"
                value={formData.password}
                onChange={handleChange}
              />
              {/* <svg>
                <use href="#svg-check" />
              </svg> */}
            </div>
            <button type="submit" className="button">
              <div className="arrow-wrapper">
                <span className="arrow" />
              </div>
              <button
                type="submit"
                disabled={isLoading}
                className="button-text"
              >
                {isLoading ? "Logging in..." : "Log in"}
              </button>
            </button>

            <p className="mb-5 d-flex justify-content-center">
              <span className="text-muted">Don't have an account?</span>{" "}
              <Link to="/register">Signup</Link>
            </p>
          </div>
        </form>
        <div className="finished">
          <svg>
            <use href="#svg-check" />
          </svg>
        </div>
      </div>
    </section>
  );
}
