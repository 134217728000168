import * as React from "react";
import { UilMultiply } from "@iconscout/react-unicons";
import { Link } from "react-router-dom";
import { useLeaderboard, useGenresList } from "../../hooks/apis/movies";
import { useLocation, useNavigate } from "react-router-dom";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import { useMovieDetails } from "../../hooks/apis/movies";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import NoData from "../common/noData";
import { getLocalstorageWithExpiration } from "./../../utils";
import MovieModal from "../common/movieModal";

export default function Index() {
  const { isLoading: isGenresListLoading, data: genresList } = useGenresList();
  const location = useLocation();
  const navigate = useNavigate();
  const type = location.state;
  const [find, setFind] = React.useState("");
  const [pageSize, setPageSize] = React.useState(20);
  const [genres, setGenres] = React.useState(null);

  const leaderboardJSON = getLocalstorageWithExpiration("leaderboard");
  const leaderboard = leaderboardJSON
    ? JSON.parse(leaderboardJSON)
        .filter((movie) => {
          const titleMatch = movie.title
            .toLowerCase()
            .includes(find?.toLowerCase());

          const genresMatch =
            genres === null ||
            movie?.genres?.some((genre) => genre.key === genres);

          const typeMatch =
            movie.type === (type == "movie" ? "movie" : "tv-show");

          return titleMatch && genresMatch && typeMatch;
        })
        .sort((a, b) => parseInt(b.score) - parseInt(a.score))
    : [];

  const handleChange = (event) => {
    setGenres(event.target.value);
  };

  const handleFindChange = (event) => {
    setFind(event.target.value);
  };


  // Movie Info Modal
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [movieID, setMovieID] = React.useState(null);
  const {
    isFetching: isFetchingMovie,
    data: movieData,
    refetch: refetchMovie,
  } = useMovieDetails(movieID, type);
  const handleInfoClick = (id) => {
    setMovieID(id);
    handleOpen();
  };

  return (
    <>
      <section className="leaderboard-section">
        <div className="d-flex justify-content-between container align-items-center">
          <h1 className="text-light text-center py-4">
            {type == "movie" ? "Movies " : "TV Shows "}Leaderboard
          </h1>
          <Link onClick={() => navigate(-1)}>
            <UilMultiply />
          </Link>
        </div>

        <div className="container">
          <div className="leader-board-filter d-flex justify-content-end mb-3">
            <FormControl className="custom_field">
              <InputLabel id="demo-simple-select-label">
                Filter by genres
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                size={"small"}
                id="demo-simple-select"
                value={genres}
                onChange={handleChange}
              >
                {genres && (
                  <MenuItem key={0} value={null} className="text-danger">
                    Clear Filter
                  </MenuItem>
                )}

                {genresList?.map((option) => (
                  <MenuItem key={option?.param} value={option?.param}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <div className="mx-1"> </div>
            <TextField
              className="custom_field"
              size={"small"}
              label="Search ..."
              variant="outlined"
              value={find}
              onChange={handleFindChange}
            />
          </div>

          <div className="leaderboard-table mb-4">
            {leaderboard?.map((movie, idx) => (
              <>
                <div
                  key={movie.id}
                  onClick={() => handleInfoClick(movie?.movie_id)}
                >
                  <div className="leaderboard-row d-flex">
                    <div className="leaderboard-cell id-cell leader-cell">
                      {idx + 1}{" "}
                      {(genres || find) && movie?.global_rank && (
                        <>({movie?.global_rank})</>
                      )}
                    </div>
                    <div className="leaderboard-cell movie-cell leader-cell">
                      {idx <= 9 && <img src={movie.image} alt={movie.title} />}

                      <div>
                        <h6 className="m-0 p-0 leader-h5">
                          {movie.title}
                          {idx + 1 === 1 && (
                            <EmojiEventsIcon className="gold-color" />
                          )}
                          {idx + 1 === 2 && (
                            <EmojiEventsIcon className="silver-color" />
                          )}
                          {idx + 1 === 3 && (
                            <EmojiEventsIcon className="bronze-color" />
                          )}
                        </h6>
                        <p className="m-0 p-0">{movie.description}</p>
                      </div>
                    </div>
                    <div className="leaderboard-cell score-cell leader-cell">
                      {movie.score}
                    </div>
                  </div>
                </div>
              </>
            ))}

            {leaderboard?.length === 0 && <NoData />}


          </div>
        </div>

        <MovieModal
          handleClose={handleClose}
          isFetchingMovie={isFetchingMovie}
          movieData={movieData}
          open={open}
        />
      </section>
    </>
  );
}
