import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import LightModeIcon from "@mui/icons-material/LightMode";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import {useLightMode} from '../../contexts/MainContext'

const ColorMode = () => {
  const { isLightMode, toggleLightMode } = useLightMode();

  return (
    <Tooltip title={"Color Mode"}>
      <Button onClick={toggleLightMode} size="small">
        {isLightMode ? (
          <LightModeIcon className="primary-color" />
        ) : (
          <Brightness4Icon className="primary-color" />
        )}
      </Button>
    </Tooltip>
  );
};

export default ColorMode;
