import React from 'react'
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";

const noData = () => {
  return (
    <div className="d-flex justify-content-center align-items-center flex-column h-100">
      <p className="text-faded2">
        <SentimentVeryDissatisfiedIcon sx={{ fontSize: "90px" }} />
      </p>
      <p className="text-faded2">No enough data yet ...</p>
    </div>
  );
}

export default noData