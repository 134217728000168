import * as React from "react";
import { UilMultiply } from "@iconscout/react-unicons";
import Poster from "../../assets/images/poster.jpg";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import imdbIcon from "../../assets/images/imdb.png";
import primeIcon from "../../assets/images/amazon.jpg";
import CircularProgress from "@mui/material/CircularProgress";

const movieModal = ({ handleClose, isFetchingMovie, movieData , open}) => {
      const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        bgcolor: "#fff",
        border: "0px",
        borderRadius: "10px",
        boxShadow: 24,
        pt: 4,
        px: 4,
        pb: 4,
        width: "35%",
        height: "80vh",
        overflow: "auto",
      };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={style}
        className="leaderboard-movie-bg"
        style={{
          backgroundImage: `url(${!isFetchingMovie && movieData?.data?.image})`,
        }}
      >
        {isFetchingMovie ? (
          <div className="movie-details d-flex justify-content-center align-items-center">
            <CircularProgress color="inherit" />
          </div>
        ) : (
          <>
            <div className="movie-details">
              {/* header */}
              <div className="movie-detail-header">
                <UilMultiply onClick={handleClose} />
              </div>
              {/* body */}
              <div className="movie-detail-body">
                <div className="d-flex justify-content-between">
                  <div className="text-start">
                    <h1>{movieData?.data?.title}</h1>
                    <p>
                      {movieData?.data?.year} | R |{" "}
                      {movieData?.data?.runtimeStr} |{" "}
                      {movieData?.data?.imDbRating}
                      <i className="uil uil-star" />
                      <img src={imdbIcon} height="25px" alt="" />
                    </p>

                    {movieData?.data?.directors.length !== 0 && (
                      <p className="detail-item directorList">
                        <span>Director : </span>
                        {movieData?.data?.directors
                          .map((item) => item.name)
                          .join(", ")}
                      </p>
                    )}
                    {movieData?.data?.writers.length !== 0 && (
                      <p className="detail-item writerList">
                        <span>Writers : </span>
                        {movieData?.data?.writers
                          .map((item) => item.name)
                          .join(", ")}
                      </p>
                    )}
                    {movieData?.data?.stars.length !== 0 && (
                      <p className="detail-item starList">
                        <span>Stars : </span>
                        {movieData?.data?.stars
                          .map((item) => item.name)
                          .join(", ")}
                      </p>
                    )}
                  </div>
                  <img
                    src={movieData?.data?.image || Poster}
                    className="thumb px-2"
                    alt={movieData?.data?.title || "No Title"}
                  />
                </div>
                <p className="detail-item mt-4 text-start">
                  <span>Storyline :</span> {movieData?.data?.description}
                </p>
              </div>
              {/* footer */}
              <div className="movie-detail-footer">
                <div className="row">
                  <div className="col-lg-6 col-12">
                    <button
                      type="button"
                      className="btn btn-outline-secondary blue-btn h6"
                    >
                      <img src={primeIcon} alt="" /> Rent 3.49 $
                    </button>
                  </div>
                  <div className="col-lg-6 col-12">
                    <button
                      type="button"
                      className="btn btn-outline-secondary blue-btn"
                    >
                      <img src={primeIcon} alt="" /> Buy 7.49 $
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <button
                      type="button"
                      className="btn btn-outline-secondary gold-btn"
                    >
                      <i className="uil uil-plus" /> Add to Watch List
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default movieModal;
