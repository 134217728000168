import { useState } from "react";
import {
  useWatchlist,
  useToggleWatchlistMovie,
  useToggleIsSeenWatchlistMovie,
} from "../../hooks/apis/movies";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { UilMultiply } from "@iconscout/react-unicons";
import NoData from "../common/noData";
import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";


  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "#fff",
    border: "0px",
    borderRadius: "10px",
    boxShadow: 24,
    pt: 4,
    px: 4,
    pb: 4,
    width: "70%",
    height: "80vh",
    overflow: "auto",
  };


export default function Index({open, handleOpen, handleClose }) {
  const [type, setType] = React.useState("movie");
  const { isFetching, data, refetch } = useWatchlist({
    type: type,
  });
  const {
    mutate: toggleWatchListMutate,
    isLoading: togglingWatchList,
    data: toggleWatchlistData,
  } = useToggleWatchlistMovie();

  const {
    mutate: toggleIsSeen,
    isLoading: togglingIsSeen,
    data: toggleIsSeenData,
  } = useToggleIsSeenWatchlistMovie();


  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style}
          className="leaderboard-movie-bg"
          //   style={{
          //     backgroundImage: `url(${
          //       !isFetchingMovie && movieData?.data?.image
          //     })`,
          //   }}
        >
          {isFetching ? (
            <div className="movie-details d-flex justify-content-center align-items-center">
              <CircularProgress color="inherit" />
            </div>
          ) : (
            <>
              <div className="movie-details">
                {/* header */}
                <div className="movie-detail-header d-flex justify-content-between align-items-center">
                  <h3 className="py-1">Watch List</h3>
                  <UilMultiply onClick={handleClose} />
                </div>
                {/* body */}
                <div className="movie-detail-body">
                  <div className="leaderboard-tabs d-flex">
                    <button
                      className={`px-5 mx-1 ${type === "movie" && "active"}`}
                      onClick={() => setType("movie")}
                    >
                      Movies
                    </button>
                    <button
                      className={`px-5 mx-1 ${type === "tv-show" && "active"}`}
                      onClick={() => setType("tv-show")}
                    >
                      Tv Shows
                    </button>
                  </div>

                  <div className="leaderboard-table mb-4">
                    {data?.results?.map((movie, idx) => (
                      <div key={movie.id}>
                        <div className="leaderboard-row d-flex">
                          <div className="leaderboard-cell id-cell leader-cell leader-cell-dark text-light">
                            {idx + 1}{" "}
                            <>
                              {movie?.global_rank && `(${movie?.global_rank})`}
                            </>
                            {/* {(genres || find) &&
                                user &&
                                movie?.global_rank && (
                                  <>({movie?.global_rank})</>
                                )} */}
                          </div>
                          <div className="leaderboard-cell movie-cell leader-cell leader-cell-dark">
                            {idx <= 9 && (
                              <img src={movie.image} alt={movie.title} />
                            )}

                            <div>
                              <h5 className="m-0 p-0 leader-h5 text-light">
                                {movie.title}{" "}
                                {movie?.year && <> | {movie?.year}</>}
                              </h5>
                              <p className="m-0 p-0 text-faded">
                                {movie.description}
                              </p>
                            </div>
                          </div>
                          <div className="leaderboard-cell score-cell leader-cell leader-cell-dark text-light">
                            <Tooltip title="Remove from watchlist">
                              <IconButton
                                onClick={() =>
                                  toggleWatchListMutate({
                                    movie_id: movie?.movie_id,
                                  })
                                }
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                            {movie?.watching_status === "1" ? (
                              <Tooltip title="Mark as unseen">
                                <IconButton
                                  onClick={() =>
                                    toggleIsSeen({
                                      movie_id: movie?.movie_id,
                                    })
                                  }
                                >
                                  <VisibilityOffIcon />
                                </IconButton>
                              </Tooltip>
                            ) : (
                              <Tooltip title="Mark as seen">
                                <IconButton
                                  onClick={() =>
                                    toggleIsSeen({
                                      movie_id: movie?.movie_id,
                                    })
                                  }
                                >
                                  <VisibilityIcon />
                                </IconButton>
                              </Tooltip>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                    {!data?.count && <NoData />}
                  </div>
                </div>
                {/* footer */}
                <div className="movie-detail-footer"></div>
              </div>
            </>
          )}
        </Box>
      </Modal>
    </>
  );
};

