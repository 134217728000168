import React from "react";
import { Navigate } from "react-router-dom";
import { getAuthToken } from "./hooks/apis/Auth";


const ProtectedRoute = ({children }) => {
  const token = getAuthToken();
  if (!token) {
    return <Navigate to="/login" replace />;
  }
  console.log(children)
  return children;
};

export default ProtectedRoute;
