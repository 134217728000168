import * as React from "react";
import { useState, useCallback, useEffect, useRef } from "react";
import Movie from "../common/movieCard";
import { useAuth } from "../../contexts/AuthContext";
import {use2RandomMovies, useSelectWinnerMovie} from "../../hooks/apis/movies";
import { useCalculateScoreGuest } from "../../hooks/apis/guest";
import {getLocalstorageWithExpiration} from "../../utils/index";
import { useLocation } from "react-router-dom";
import { useSetUserFilter, useGetUserFilter } from "../../hooks/apis/movies";
import { throttle } from "lodash";
import Vs from './Vs'


export default function Index({ open, filterOpen, setFilterOpen }) {
  const {user} = useAuth();
  const [presedKey, setPresedKey] = useState(null);
  const { mutate, selectWinnerResponseData, isLoading } =
    useSelectWinnerMovie();
  const {
    mutate: mutateScoreCalculat,
    data: calculatedScoreDataGuest,
    isLoading: isCalculatingScoreGuest,
  } = useCalculateScoreGuest();
  const leaderboardJSON = getLocalstorageWithExpiration("leaderboard");
  const leaderboard = leaderboardJSON ? JSON.parse(leaderboardJSON) : [];

  const searchJSON = getLocalstorageWithExpiration("selected_search");
  const GuestSearchData = searchJSON ? JSON.parse(searchJSON) : null;

  const location = useLocation();
  const path = location?.pathname;
  const type = path === "/tv-shows" ? "tv-show" : "movie";

  const { isFetching, data, refetch } = use2RandomMovies({ type: type });
  const { data: userFilter } = useGetUserFilter();
  const { mutate: mutateUsetFilter, isLoading: clearingFilter } = useSetUserFilter();

  const handleClearFilter = () => {
    mutateUsetFilter({
      year_from: null,
      year_to: null,
      genres: [],
      companies: [],
      providers: [],
      plot: null,
      status: "1", //All
      rating: "1", //All
      cycle_ranked_through: "0", //All
    });
    setFilterOpen(false);
  };


  const handleKeyDown = (event) => {
    if (event.key === "a") {
      setPresedKey("a");
      handleSelectWinner(data?.movie_A, data?.movie_B, "slot_a");
      
    } else if (event.key === "d") {
      setPresedKey("d");
      handleSelectWinner(data?.movie_B, data?.movie_A, "slot_b");
      
    } else if (event.key === " " || event.key === "s") {
      setPresedKey("s");
      refetch();
      
    } else if (event.key === "f") {
      setPresedKey("f");
      setFilterOpen(true);
      
    } else if (event.key === "c") {
      setPresedKey("c");
      handleClearFilter();
      
    }
  };

  React.useEffect(() => {
    refetch();
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  React.useEffect(() => {
    if (!open && !filterOpen) {
      document.addEventListener("keydown", handleKeyDown);
      return () => {
        document.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [open, filterOpen, data]);






  const throttledHandleSelectWinner = useRef(
    throttle(
      (winner, loser, slot) => {
        winner["type"] = type;
        winner["slot"] = slot;
        loser["type"] = type;

        let data = {
          winner: winner,
          loser: loser,
          type: type,
        };
        if (user) {
          mutate(data);
        } else {
          mutateScoreCalculat(data);
        }
      },
      5000,
      { leading: true, trailing: false }
    )
  ).current;

  useEffect(() => {
    return () => {
      // Cleanup the throttled function on component unmount
      throttledHandleSelectWinner.cancel();
    };
  }, [throttledHandleSelectWinner]);

  const handleSelectWinner = useCallback(
    (winner, loser, slot) => {
      throttledHandleSelectWinner(winner, loser, slot);
    },
    [throttledHandleSelectWinner]
  );







  return (
    <>
      <section
        className="home-section"
        onKeyDown={!open && !filterOpen && handleKeyDown}
      >
        <div className="ad" style={{ width: "15%" }}>
          <div
            className="text-primary d-flex justify-content-center align-items-center  text-dark"
            style={{ width: "90%", height: "95%" }}
          >
            {" "}
            <div
              class="mt-4 advertise-placeholder"
              style={{ width: "160px", height: "600px" }}
            >
              <span>Ad Area</span>
              <span>160 X 600</span>
            </div>
          </div>
        </div>

        <div className="movies-row">
          <Movie
            slot="slot_a"
            movie={
              isLoading
                ? null
                : (!user && GuestSearchData?.type == type && GuestSearchData) ||
                  selectWinnerResponseData?.movie_A ||
                  calculatedScoreDataGuest?.movie_A ||
                  // getRandomObjectFromArray(leaderboard) ||
                  data?.movie_A ||
                  null
            }
            loser={
              isLoading
                ? null
                : selectWinnerResponseData?.movie_B ||
                  calculatedScoreDataGuest?.movie_B ||
                  // getRandomObjectFromArray(leaderboard) ||
                  data?.movie_B ||
                  null
            }
            handleSelectWinner={handleSelectWinner}
            isLoading={isLoading}
            isFetching={isFetching}
            isCalculatingScoreGuest={isCalculatingScoreGuest}
            refetch2RandomMovies={refetch}
          />

          <Vs
            isFetching={isFetching}
            refetch={refetch}
            presedKey={presedKey}
            userFilter={userFilter}
            clearingFilter={clearingFilter}
          />

          <Movie
            slot="slot_b"
            movie={
              isLoading
                ? null
                : selectWinnerResponseData?.movie_B ||
                  calculatedScoreDataGuest?.movie_B ||
                  // getRandomObjectFromArray(leaderboard) ||
                  data?.movie_B ||
                  null
            }
            loser={
              isLoading
                ? null
                : selectWinnerResponseData?.movie_A ||
                  calculatedScoreDataGuest?.movie_A ||
                  // getRandomObjectFromArray(leaderboard) ||
                  data?.movie_A ||
                  null
            }
            handleSelectWinner={handleSelectWinner}
            isLoading={isLoading}
            isFetching={isFetching}
            isCalculatingScoreGuest={isCalculatingScoreGuest}
            refetch2RandomMovies={refetch}
          />
        </div>
        <div className="ad" style={{ width: "15%" }}>
          <div
            className="text-primary d-flex justify-content-center align-items-center  text-dark"
            style={{ width: "90%", height: "95%" }}
          >
            <div
              class="mt-4 advertise-placeholder"
              style={{ width: "160px", height: "600px" }}
            >
              <span>Ad Area</span>
              <span>160 X 600</span>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
