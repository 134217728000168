import * as React from "react";
import Chip from "@mui/joy/Chip";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import Tooltip from "@mui/joy/Tooltip";



const levelBadge = ({user , hideToolTip}) => {
  return (
    <Tooltip
      title={
        !hideToolTip &&
        <div className="py-1 px-2">
          {user?.level?.has_next_level ? (
            <p className="mb-1">Next level progress</p>
          ) : (
            <p className="mb-1">You reached the highest level!</p>
          )}

          <div class="progress">
            <div
              className={`progress-bar progress-bar-striped ${
                user?.level?.percentage <= 25
                  ? "bg-warning"
                  : user?.level?.percentage > 75
                  ? "bg-danger"
                  : user?.level?.percentage > 50
                  ? "bg-info"
                  : ""
              }`}
              role="progressbar"
              style={{ width: `${user?.level?.percentage}%` }}
              aria-valuenow={user?.level?.xp_to}
              aria-valuemin={user?.level?.xp_from}
              aria-valuemax={user?.level?.xp_to}
            ></div>
          </div>

          <div className="d-flex justify-content-between">
            <span className="mx-1">{user?.xp}xp</span>
            <span className="mx-1">of</span>
            <span className="mx-1">{user?.level?.xp_to}xp</span>
          </div>
        </div>
      }
    >
      <Chip
        startDecorator={<EmojiEventsIcon style={{ fontSize: 10 }} />}
        size="md"
        style={{
          background: user?.level?.color,
          color: user?.level?.text,
        }}
      >
        <span className="font-weight-bolder">
          {user?.level?.name} ({user?.xp}xp)
        </span>
      </Chip>
    </Tooltip>
  );
}

export default levelBadge