import { useMutation, useQueryClient, useQuery } from "react-query";
import { toast } from "react-toastify";
import api from "../axios"
import {getLocalstorageWithExpiration} from "./../../../utils";
import { useAuth } from "../../../contexts/AuthContext";


export function useLogin() {
  const { login } = useAuth();
  const queryClient = useQueryClient();

  return useMutation(
    async (data) => {
      const response = await api.post("/auth/login/", data);
      return response.data;
    },
    {
      onSuccess: (data) => {
        login(data)
        queryClient.invalidateQueries("currentUser");
      },
    }
  );
}


export function useChangePassword() {
  return useMutation(
    async (data) => {
      const response = await api.post("/auth/users/set_password/", data);
      return response.data;
    },
    {
      onSuccess: (data) => {
        toast.success("Password changed successfully ...");
      },
    }
  );
}


export function useSignup() {

  return useMutation(
    async (data) => {
      const response = await api.post("/auth/users/", data);
      return response.data;
    },
    {
      onSuccess: (data) => {
        toast.success("Account created successfully ,Please check your mail to activate account.");
      },
    }
  );
}



export function useUpdateProfile() {
  const queryClient = useQueryClient();
  return useMutation(
    async (formData) => {
      const response = await api.patch("/auth/users/me/", formData, {
        headers: {
          "Content-Type": "multipart/form-data", // Set the content type to multipart/form-data for file upload
        },
      });
      return response.data;
    },
    {
      onSuccess: (data) => {
        toast.success("Profile updated.");
        queryClient.invalidateQueries("profile");
      },
    }
  );
}

const getProfile = async (key, params) => {
  const response = await api.get("/auth/users/me/", {
    params: params,
  });
  return response.data;
};

export function useProfile(params) {
  return useQuery(["profile", params], () => getProfile("profile", params));
};


const getUsersList = async (key, params) => {
  const response = await api.get("/auth/users/", {
    params: params,
  });
  return response.data;
};

export function useUsersList(params) {
  return useQuery(
    ["users-list", params],
    () => getUsersList("users-list", params),
    { enabled: !!params.find } // Only enable the query if `find` has a value
  );
};


const getSingleUser = async (params) => {
  const { id } = params;
  const response = await api.get(`/auth/users/${id}/`, {
    params,
  });
  return response.data;
};

export function useSingleUser(params) {
  return useQuery(
    ["user", params.id],
    () => getSingleUser(params),
    {
      enabled: !!params.id,
    }
  );
};


const getCountries = async (key, params) => {
  const response = await api.get("/api/common/countries/", {
    params: params,
  });
  return response.data;
};

export function useCountries(params) {
  return useQuery(["countries", params], () => getCountries("countries", params));
};


export function getAuthToken() {
  return getLocalstorageWithExpiration("token") || null;
}

export function getUser() {
  let user = getLocalstorageWithExpiration("user")
  if (user){
    return JSON.parse(user)
  }
  return null;
}

export function setAuthToken(token) {
  api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

export function HandleLogout() {
  // const navigate = useNavigate();
  delete api.defaults.headers.common["Authorization"];
  // localStorage.removeItem("token");
  // localStorage.removeItem("user");
  // localStorage.removeItem("selected_search");
  // localStorage.removeItem("leaderboard");
  // navigate("/login");
}

export default api;
