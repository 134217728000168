import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { UilSearch, UilPlus } from "@iconscout/react-unicons";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import {
  useSelectSearchResult,
  useSearchMovie,
} from "../../hooks/apis/movies"; 
import { useNavigate, useLocation } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import placeholderPoster from '../../assets/images/MoviePosterPlaceholder.png'
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#fff",
  border: "2px solid #eee",
  borderRadius: "10px",
  boxShadow: 24,
  pt: 4,
  px: 4,
  pb: 4,
  width: "50%",
  textAlign: "center",
  height: "80vh",
  overflow: "auto"
};


export default function BasicModal({ open, setOpen }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = React.useState({
    find: "",
    adult: false,
    type: location?.pathname === "/tv-shows" ? "tv-show" : "movie",
  });
  const {data, isLoading: isSearching} = useSearchMovie(searchParams);
  const { mutate } = useSelectSearchResult();
  const handleOpen = () => {
    setOpen(true);
    setSearchParams((prevFilter) => ({
      ...prevFilter,
      type: location?.pathname === "/tv-shows" ? "tv-show" : "movie",
    }));
  };
  const handleClose = () => {
    setOpen(false)
    setSearchParams((prevFilter) => ({
      ...prevFilter,
      type: location?.pathname === "/tv-shows" ? "tv-show" : "movie",
    }));
  };

  const handleCompareA = (movie_id) => {
    setSearchParams({
      find: "",
      genres: [].join(","),
    });
    handleClose();
    navigate(location?.pathname);
    mutate({
      movie_id,
      type: location?.pathname === "/tv-shows" ? "tv-show" : "movie",
    });
  };

  const handleDateChange = (date, name) => {
    setSearchParams((prevFilter) => ({
      ...prevFilter,
      [name]: moment(date).format("YYYY"),
    }));
  };

  return (
    <div>
      <Button
        onClick={handleOpen}
        disabled={
          location?.pathname !== "/movies" && location?.pathname !== "/tv-shows"
        }
      >
        <UilSearch className="text-light" />
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="d-flex justify-content-between">
            <h3 className="d-flex justify-content-start mb-4">Search</h3>
            <h1 className="d-flex justify-content-end mb-4">
              <CloseIcon style={{ cursor: "pointer" }} onClick={handleClose} />
            </h1>
          </div>

          <FormControl
            variant="standard"
            className="d-flex justify-content-between"
          >
            <div className="row">
              <div className="col-12 col-lg-6">
                <TextField
                  className="w-100"
                  onChange={(event) => {
                    const enteredText = event.target.value;
                    if (enteredText.length > 1) {
                      setSearchParams((prevSearchParams) => ({
                        ...prevSearchParams,
                        find: enteredText,
                      }));
                    }

                    if (enteredText.length === 0) {
                      setSearchParams((prevSearchParams) => ({
                        ...prevSearchParams,
                        find: "",
                      }));
                    }
                  }}
                  label={`Search ${
                    location?.pathname === "/tv-shows" ? "TV Shows" : "Movies"
                  }`}
                  required
                />
                <div className="d-flex">
                  <FormControlLabel
                    control={
                      <Switch
                        checked={searchParams?.adult}
                        onChange={(event) =>
                          setSearchParams((prevSearchParams) => ({
                            ...prevSearchParams,
                            adult: event.target.checked,
                          }))
                        }
                      />
                    }
                    label="Include adult"
                  />
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <FormControl className="w-100">
                  <DatePicker
                    views={["year"]}
                    value={searchParams.year ? moment(searchParams.year) : null}
                    onChange={(date) => handleDateChange(date, "year")}
                    inputVariant="outlined"
                    maxDate={moment(new Date(), "yyyy-MM-dd")}
                  />
                </FormControl>
                <Typography
                  variant="caption"
                  className="d-flex text-muted mt-2"
                >
                  Select specific year or leave blank
                </Typography>
              </div>
            </div>
          </FormControl>

          {isSearching && <span>Loading....</span>}

          {data?.results?.length !== 0 && <hr />}
          {data?.results?.map((movie) => {
            return (
              <Box key={movie.movie_id}>
                <div className="d-flex justify-content-between align-items-center text-start mb-2">
                  <div className="d-flex align-items-center">
                    <img
                      src={movie.thumbnail || placeholderPoster}
                      alt=""
                      className="search-poster-image"
                    />
                    <div className="px-2">
                      <Typography className="m-0 p-0" variant="h6">
                        {movie.original_name || movie.title}
                      </Typography>
                      <Typography
                        className="text-muted m-0 p-0"
                        variant="caption"
                      >
                        {movie.overview && (
                          <p>
                            {movie.overview.length > 200
                              ? `${movie.overview.substring(0, 200)}...`
                              : movie.overview}
                          </p>
                        )}
                      </Typography>
                    </div>
                  </div>
                  <div
                    className="d-flex justify-content-end"
                    style={{ width: "250px" }}
                  >
                    <Button
                      size="small"
                      color="primary"
                      variant="outlined"
                      onClick={() => handleCompareA(movie?.movie_id)}
                    >
                      <UilPlus />
                      Select Movie
                    </Button>
                  </div>
                </div>
              </Box>
            );
          })}
        </Box>
      </Modal>
    </div>
  );
}
