import *  as React from "react";
import { useEffect } from "react";
import Routes from "./Routes";
import Nav from "./components/parts/nav";
import TopNav from "./components/parts/topNav";
import { ToastContainer } from "react-toastify";
import "swiper/css";
import "react-toastify/dist/ReactToastify.css";
import "./assets/css/style.css";

function App() {
  const [open, setOpen] = React.useState(false);
  const [filterOpen, setFilterOpen] = React.useState(false);

    useEffect(() => {
      const handleTabClose = (event) => {
        localStorage.removeItem("selected_search");
        localStorage.removeItem("leaderboard");
      };

      window.addEventListener("beforeunload", handleTabClose);

      return () => {
        window.removeEventListener("beforeunload", handleTabClose);
      };
    }, []);


  return (
      <>
          <div className="w-100">
              <TopNav />
              <Routes
                  open={open}
                  setOpen={setOpen}
                  filterOpen={filterOpen}
                  setFilterOpen={setFilterOpen}
              />
              <Nav
                  open={open}
                  setOpen={setOpen}
                  filterOpen={filterOpen}
                  setFilterOpen={setFilterOpen}
              />
          </div>

          <ToastContainer
              position="bottom-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
          />
      </>
  );
}

export default App;
