import * as React from "react";
import imdbIcon from '../../assets/images/imdb.png'
import primeIcon from '../../assets/images/amazon.jpg'
import { UilInfoCircle } from "@iconscout/react-unicons";
import { UilMultiply } from "@iconscout/react-unicons";
import CircularProgress from "@mui/material/CircularProgress";
import { useMovieDetails } from "../../hooks/apis/movies"; 
import Skeleton from "@mui/material/Skeleton";
import {
  useToggleWatchlistMovie,
  useUpdateMovie,
  useHideMovie
} from "../../hooks/apis/movies";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import imdbImage from "../../assets/images/imdb.png";
import Tooltip from "@mui/joy/Tooltip";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockIcon from "@mui/icons-material/Lock";
import { useLocation } from "react-router-dom";
import ReactImageVideoLightbox from "react-image-video-lightbox";
import LocalMoviesIcon from "@mui/icons-material/LocalMovies";
import { getUser } from "../../hooks/apis/Auth";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Chip from "@mui/material/Chip";
import placeholderPoster from "../../assets/images/MoviePosterPlaceholder.png";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import TheatersIcon from "@mui/icons-material/Theaters";
import IconButton from "@mui/material/IconButton";
import HideSourceIcon from "@mui/icons-material/HideSource";
import useImageColor from "use-image-color";


export default function Index({
  movie,
  loser,
  handleSelectWinner,
  isLoading,
  isCalculatingScoreGuest,
  refetch2RandomMovies,
  slot
}) {
  const location = useLocation();
  const user = getUser();
  const [showInfo, setShowInfo] = React.useState(false);
  const [watchStatus, setWatchStatus] = React.useState(null);
  const [inWatchList, setInWatchList] = React.useState(false);
  const [showTrailerLightBox, setShowTrailerLightBox] = React.useState(false);
  const [movieID, setMovieID] = React.useState(null);
  const { isFetching, data, refetch } = useMovieDetails(
    movieID,
    location?.pathname === "/tv-shows" ? "tv-show" : "movie"
  );
  const [isHovered, setIsHovered] = React.useState(false);
  // const { colors } = useImageColor("https://letsenhance.io/static/8f5e523ee6b2479e26ecc91b9c25261e/1015f/MainAfter.jpg", { cors: true, colors: 2 });
  const { colors } = useImageColor(movie?.image, { cors: true, colors: 2 });



  const {mutate: updateMovie, data:updateMovieData} = useUpdateMovie();
  const { mutate: hideMovie } = useHideMovie();

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };


  const handleInfoClick = (id) => {
    setMovieID(id);
    setShowInfo(true);
  };
  const handleTrailerBtnClick = () => {
    // trailerReFetch();
    setShowTrailerLightBox(!showTrailerLightBox);
  };
  const handleInfoClose = () => {
    setShowInfo(false);
  };

  React.useEffect(() => {
    refetch();
    setInWatchList(movie?.in_watch_list);
    setWatchStatus(
        movie?.watching_status_text ? movie?.watching_status_text : "Not Seen"
    );
    
  }, [movieID]);

  React.useEffect(() => {
      setShowInfo(false);
      setWatchStatus(
          movie?.watching_status_text ? movie?.watching_status_text : "Not Seen"
      );
  }, [movie]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
      setAnchorEl(null);
  };

  return (
    <>
      {showTrailerLightBox && (
        <div className="trailer-lightbox" style={{ zIndex: 999999 }}>
          <ReactImageVideoLightbox
            data={[
              {
                url: data?.data?.trailer,
                type: "video",
                title: data?.title,
              },
            ]}
            startIndex={0}
            showResourceCount={true}
            onCloseCallback={() => setShowTrailerLightBox(false)}
            onNavigationCallback={(currentIndex) =>
              console.log(`Current index: ${currentIndex}`)
            }
          />
        </div>
      )}

      <div
        style={{
          boxShadow: `0 9px 0px 0px transparent, 0 -9px 0px 0px transparent, 12px 0 15px -4px ${colors?.[0]}, -12px 0 15px -4px ${colors?.[0]}`,
        }}
        className="poster-section"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {isHovered && !showInfo && (
          <span
            className="select-movie-btn"
            onClick={() => handleSelectWinner(movie, loser, slot)}
          >
            {!isLoading ? (
              <>
                <ThumbUpIcon
                  className="mb-3 white-color"
                  style={{ fontSize: "75px" }}
                />
              </>
            ) : (
              <>Loading</>
            )}
          </span>
        )}

        {movie?.image ? (
          <img
            src={movie.image}
            className="poster"
            alt={movie?.title || "No Title"}
          />
        ) : (
          <Skeleton
            variant="rectangular"
            className="poster-skeleton"
            animation="wave"
            sx={{ bgcolor: "grey.900" }}
          />
        )}

        <div className="movie-bottom-info d-flex align-items-end justify-content-end">
          {/* <h3 className="text-light">{movie?.title}</h3> */}
          {movie?.imDbRating && (
            <div className="d-flex flex-column align-items-center">
              <h6 className="text-light m-0 p-0">
                {movie?.imDbRating.toFixed(1)}
              </h6>
              <img src={imdbImage} height="20" alt="" />
            </div>
          )}
        </div>

        <h6 className="movie-bttom-score" style={{ color: movie?.color }}>
          <span id="movie-title">
            <div>
              {user && !isLoading && (
                <>
                  <Tooltip
                    arrow
                    title={watchStatus && watchStatus}
                    size="sm"
                    color={watchStatus === "Watched" ? "success" : "neutral"}
                  >
                    <IconButton
                      className="mx-2"
                      size="small"
                      variant="contained"
                      aria-controls={open ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      onClick={handleClick}
                      color={
                        (watchStatus === "Watched" && "success") ||
                        (watchStatus === "Not Watched" && "info") ||
                        (watchStatus === "Add to watchlist" && "warning") ||
                        "info"
                      }
                    >
                      {(watchStatus === "Watched" && <VisibilityIcon />) ||
                        (watchStatus === "Not Watched" && (
                          <VisibilityOffIcon />
                        )) ||
                        (watchStatus === "Add to watchlist" && (
                          <TheatersIcon />
                        )) || <VisibilityOffIcon />}
                    </IconButton>
                  </Tooltip>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem
                      onClick={() => {
                        updateMovie({
                          movie_id: movie?.movie_id,
                          type: movie?.type,
                          watching_status: "1",
                        });
                        handleClose();
                        setWatchStatus("Watched");
                      }}
                    >
                      Watched
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        updateMovie({
                          movie_id: movie?.movie_id,
                          type: movie?.type,
                          watching_status: "2",
                        });
                        handleClose();
                        setWatchStatus("Not Watched");
                      }}
                    >
                      Not Watched
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        updateMovie({
                          movie_id: movie?.movie_id,
                          type: movie?.type,
                          watching_status: "3",
                          in_watchlist: true,
                        });
                        handleClose();
                        setWatchStatus("Add to watchlist");
                      }}
                    >
                      Add to watchlist
                    </MenuItem>
                  </Menu>
                </>
              )}
              {user && !isLoading && movie?.title}
              {user && !isLoading && (
                <>{movie?.year && <> ({movie?.year})</>}</>
              )}
              {!user && !isCalculatingScoreGuest && movie?.title}
            </div>
          </span>

          <span id="movie-score">
            {user && (!isLoading ? movie?.score : "Updating...")}
            {!user && (!isCalculatingScoreGuest ? movie?.score : "Updating...")}
          </span>
        </h6>

        {!showInfo && (
          <>
            <UilInfoCircle
              className="details-icon white-color"
              onClick={() => handleInfoClick(movie?.movie_id)}
            />
            {movie?.is_guest_search && (
              <SearchOffIcon
                className="exit-search-icon white-color"
                onClick={() => {
                  localStorage.removeItem("selected_search");
                  refetch2RandomMovies();
                }}
              />
            )}

            {movie?.is_search && (
              <SearchOffIcon
                className="exit-search-icon white-color"
                onClick={() =>
                  updateMovie({
                    movie_id: movie?.movie_id,
                    type:
                      location?.pathname == "/tv-shows" ? "tv-show" : "movie",
                    is_search: false,
                  })
                }
              />
            )}

            {user && !movie?.is_locked && !movie?.is_search && (
              <Tooltip title="Lock">
                <LockOpenIcon
                  className="exit-search-icon white-color"
                  onClick={() =>
                    updateMovie({
                      movie_id: movie?.movie_id,
                      type:
                        location?.pathname == "/tv-shows" ? "tv-show" : "movie",
                      is_locked: true,
                    })
                  }
                />
              </Tooltip>
            )}

            {user && movie?.is_locked && !movie?.is_search && movie?.id && (
              <Tooltip title="Unlock">
                <LockIcon
                  className="exit-search-icon white-color"
                  onClick={() =>
                    updateMovie({
                      movie_id: movie?.movie_id,
                      type:
                        location?.pathname == "/tv-shows" ? "tv-show" : "movie",
                      is_locked: false,
                    })
                  }
                />
              </Tooltip>
            )}
          </>
        )}

        {showInfo &&
          (!isFetching ? (
            <>
              <div className="movie-details">
                {/* header */}
                <div className="movie-detail-header">
                  <UilMultiply class="text-color" onClick={handleInfoClose} />

                  {user && !movie?.is_search && (
                    <Tooltip title="Don't show again">
                      <HideSourceIcon
                        className="hide-icon white-color"
                        onClick={() =>
                          hideMovie({
                            movie_id: movie?.movie_id,
                            type:
                              location?.pathname == "/tv-shows"
                                ? "tv-show"
                                : "movie",
                          })
                        }
                      />
                    </Tooltip>
                  )}
                </div>
                {/* body */}
                <div className="movie-detail-body">
                  <div className="d-flex justify-content-between mb-3">
                    <div>
                      <h1 class="text-color">{data?.data?.title}</h1>
                      <p class="text-color">
                        {data?.data?.year} | R | {data?.data?.runtimeStr} |{" "}
                        {data?.data?.imDbRating.toFixed(1)}
                        <i className="uil uil-star" />
                        <img src={imdbIcon} height="25px" alt="" />
                      </p>
                    </div>
                    <img
                      src={movie?.image || placeholderPoster}
                      className="thumb px-2"
                      alt={data?.data?.title || "No Title"}
                    />
                  </div>

                  {data?.data?.directors.length !== 0 && (
                    <p className="detail-item directorList text-color">
                      <span>Director : </span>
                      {data?.data?.directors.length < 6
                        ? data?.data?.directors
                            .map((item) => item.name)
                            .join(", ")
                        : data?.data?.directors
                            .slice(0, 6)
                            .map((item) => item.name)
                            .join(", ")}
                    </p>
                  )}

                  {data?.data?.writers.length !== 0 && (
                    <p className="detail-item writerList text-color">
                      <span>Writers : </span>
                      {data?.data?.writers.length < 6
                        ? data?.data?.writers
                            .map((item) => item.name)
                            .join(", ")
                        : data?.data?.writers
                            .slice(0, 6)
                            .map((item) => item.name)
                            .join(", ")}
                    </p>
                  )}

                  {data?.data?.stars.length !== 0 && (
                    <p className="detail-item starList text-color">
                      <span>Stars : </span>
                      {data?.data?.stars.length < 6
                        ? data?.data?.stars.map((item) => item.name).join(", ")
                        : data?.data?.stars
                            .slice(0, 6)
                            .map((item) => item.name)
                            .join(", ")}
                    </p>
                  )}
                  <p className="detail-item mt-4 text-color">
                    <span>Storyline :</span> {data?.data?.description}
                  </p>

                  {data?.data?.watch_providers?.rent?.map((item) => {
                    return <>{item}</>;
                  })}
                </div>
                {/* footer */}
                <div className="movie-detail-footer text-color">
                  <div className="row">
                    {data?.data?.amazon_link ? (
                      <>
                        <div className="col-lg-12 col-12">
                          <Button
                            className="btn btn-outline-secondary blue-btn d-flex align-items-center"
                            href={data?.data?.amazon_link}
                            target="_blank"
                            component="a"
                          >
                            <Avatar
                              variant="square"
                              sx={{
                                bgcolor: "#fff",
                                borderRadius: "5px",
                                margin: "0 5px",
                                width: 28,
                                height: 28,
                              }}
                              src="https://image.tmdb.org/t/p/w500/seGSXajazLMCKGB5hnRCidtjay1.jpg"
                            />
                            Buy or Rent
                          </Button>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="col-lg-6 col-12">
                          <Typography>Buy</Typography>
                          <Button
                            className="btn btn-outline-secondary blue-btn d-flex align-items-center"
                            href={data?.data?.buy_rent_link}
                            target="_blank"
                            component="a"
                          >
                            {data?.data?.buy_providers[0]?.map((item) => {
                              return (
                                <Avatar
                                  variant="square"
                                  sx={{
                                    bgcolor: "#fff",
                                    borderRadius: "5px",
                                    margin: "0 5px",
                                    width: 28,
                                    height: 28,
                                  }}
                                  src={item}
                                />
                              );
                            })}
                            {data?.data?.buy_providers[0].length === 0 && "Buy"}
                          </Button>
                        </div>
                        <div className="col-lg-6 col-12">
                          <Typography>Rent</Typography>
                          <Button
                            className="btn btn-outline-secondary blue-btn d-flex align-items-center"
                            href={data?.data?.buy_rent_link}
                            target="_blank"
                            component="a"
                          >
                            {data?.data?.rent_providers[0]?.map((item) => {
                              return (
                                <Avatar
                                  variant="square"
                                  sx={{
                                    bgcolor: "#fff",
                                    width: 28,
                                    height: 28,
                                    borderRadius: "5px",
                                    margin: "0 5px",
                                  }}
                                  src={item}
                                />
                              );
                            })}
                            {data?.data?.rent_providers[0].length === 0 &&
                              "Rent"}
                          </Button>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-12">
                      <button
                        type="button"
                        onClick={() => {
                          updateMovie({
                            movie_id: movie?.movie_id,
                            type: movie?.type,
                            in_watchlist: !data?.data?.in_watchlist,
                            watching_status: data?.data?.in_watchlist
                              ? "2"
                              : "3",
                          });
                          setWatchStatus(
                            !data?.data?.in_watchlist
                              ? "Add to watchlist"
                              : "Not Watched"
                          );
                        }}
                        className="btn btn-outline-secondary gold-btn"
                      >
                        <i className="uil uil-plus" />
                        {isFetching
                          ? "..."
                          : data?.data?.in_watchlist
                          ? "Remove from Watch List"
                          : "Add to Watch List"}
                      </button>
                    </div>

                    <div className="col-lg-6 col-12">
                      <button
                        type="button"
                        className="btn btn-outline-secondary blue-btn"
                        onClick={() => handleTrailerBtnClick()}
                      >
                        <LocalMoviesIcon /> Trailer
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="movie-details">
              <CircularProgress className="text-color" color="inherit" />
            </div>
          ))}
      </div>
    </>
  );
}
