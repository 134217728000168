// Function to set data with an expiration time in localStorage
export const setLocalstorageWithExpiration = (key, value, expirationHours) => {
    const now = new Date();
    const item = {
        value: value,
        expiry: now.getTime() + 2 * 60 * 60 * 1000, // Expiry time in milliseconds // 2hours
    };
    localStorage.setItem(key, JSON.stringify(item));
};

export const setLocalstorageWithLongExpiration = (key, value, expirationHours) => {
    const now = new Date();
    const item = {
        value: value,
        expiry: now.getTime() + 2 * 60 * 60 * 10000000, // Expiry time in milliseconds // 2hours
    };
    localStorage.setItem(key, JSON.stringify(item));
};


// Function to get data from localStorage
export const getLocalstorageWithExpiration = (key) => {
    const itemStr = localStorage.getItem(key);
    if (!itemStr) {
        return null;
    }
    const item = JSON.parse(itemStr);
    const now = new Date();
    if (now.getTime() > item.expiry) {
        localStorage.removeItem(key);
        return null;
    }
    return item.value;
};
