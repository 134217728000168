import React from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import TrendingUpOutlinedIcon from "@mui/icons-material/TrendingUpOutlined";
import TrendingDownOutlinedIcon from "@mui/icons-material/TrendingDownOutlined";
import placeholderPoster from "../../assets/images/MoviePosterPlaceholder.png";
import RemoveIcon from "@mui/icons-material/Remove";

// MovieScore component
const MovieScore = ({ movie }) => (
  <div className="d-flex justify-content-between align-items-center">
    <img
      src={movie?.image || placeholderPoster}
      alt={movie?.title || "No title"}
      className="movie-poster-toast my-2"
    />
    <div className="px-2 d-flex flex-column justify-content-center align-items-center w-100">
      <h6 className="text-start">{movie?.title || "No title"}</h6>
    </div>
    <div
      style={{ width: "135px" }}
      className="d-flex flex-column justify-content-center align-items-center"
    >
      {movie?.is_winner ? (
        <TrendingUpOutlinedIcon style={{ color: movie?.color }} />
      ) : movie?.score_loss === 0 ? (
        <RemoveIcon style={{ color: "gray" }} />
      ) : (
        <TrendingDownOutlinedIcon style={{ color: movie?.color }} />
      )}
      <h6 className="px-2 text-end fw-bold" style={{ color: movie?.color , whiteSpace:'no-wrap' }}>
        {movie?.is_winner
          ? `+${movie?.score_gain}`
          : movie?.score_loss !== 0 && `-${movie?.score_loss}`}
      </h6>
    </div>
  </div>
);

// PropTypes for MovieScore component
MovieScore.propTypes = {
  movie: PropTypes.shape({
    image: PropTypes.string,
    title: PropTypes.string,
    is_winner: PropTypes.bool,
    color: PropTypes.string,
    score_gain: PropTypes.number,
    score_loss: PropTypes.number,
  }),
};

// Main function to raise score gain and loss toast
const RaiseScoreGainAndLoss = (data) => {
  toast(
    <>
      <MovieScore movie={data?.movie_A} />
      <MovieScore movie={data?.movie_B} />
    </>,
    {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    }
  );
};



export default RaiseScoreGainAndLoss;
