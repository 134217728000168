import * as React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import {
  useCreatePlaylist,
  useUpdatePlaylist,
  useGetPlaylist,
  useGenresList,
} from "../../hooks/apis/movies";
import { useSearchMovie } from "../../hooks/apis/movies"; 
import { useLocation } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import Switch from "@mui/material/Switch";
import placeholderPoster from "../../assets/images/MoviePosterPlaceholder.png";
import Typography from "@mui/material/Typography";
import {
  FormControl,
  FormLabel,
  Checkbox,
  Button,
  TextField,
  ListItemText,
  Chip,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";



const CreateModal = ({
  handleClose,
  open,
  type,
  playlistID,
  formData,
  setFormData,
  handleResetFormData,
}) => {
  const location = useLocation();
  const [searchParams, setSearchParams] = React.useState({
    type: type,
    playlistID: playlistID,
  });
  const { data, isLoading: isSearching } = useSearchMovie(searchParams);
  const { data: playlistData } = useGetPlaylist(playlistID);

  const { mutate: updatePlaylist } = useUpdatePlaylist();

  const { mutate, isLoading } = useCreatePlaylist();

  const { data: genresList } = useGenresList({
    type: type,
  });

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "#fff",
    border: "2px solid #eee",
    borderRadius: "10px",
    boxShadow: 24,
    pt: 4,
    px: 4,
    pb: 4,
    width: "70%",
    textAlign: "center",
    height: "80vh",
    overflowX: "hidden",
    overflowY: "scroll",
    padding: "1rem",
  };

  const handleToggleAddMovie = (movieData) => {
    setFormData((prevFormData) => {
      const movieExists = prevFormData.movies.some(
        (movie) => movie.id === movieData.id
      );

      const movies = movieExists
        ? prevFormData.movies.filter((movie) => movie.id !== movieData.id) // Remove the movie object if it exists
        : [...prevFormData.movies, movieData]; // Add the movie object if it doesn't exist

      return {
        ...prevFormData,
        movies,
      };
    });
  };

  const isMovieDataExists = (movieData) => {
    return formData?.movies.some((movie) => movie.id === movieData.id);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let formatted_formdata = {
      ...formData,
      movies: formData?.movies.map((movie) => movie.id),
    };

    if (playlistID) {
      updatePlaylist({ playlistID, data: formatted_formdata });
    } else {
      mutate(formatted_formdata);
      handleResetFormData();
    }
    handleClose();
  };

  const handleGenreChange = (event) => {
    const { value } = event.target;
    setFormData((prevFilter) => ({
      ...prevFilter,
      genres: value,
    }));
  };

  React.useEffect(() => {
    handleResetFormData();
    setFormData((prevFormData) => ({
      ...prevFormData,
      type: type,
    }));
    setSearchParams((prev) => ({
      ...prev,
      type: type,
    }));
  }, [type]);

  React.useEffect(() => {
    if (playlistData) {
      setFormData({
        name: playlistData?.data?.name || "No Name", // Provide default values or empty strings if not available
        movies: playlistData?.data?.movies || [],
        genres: playlistData?.data?.genres || [],
        is_public: playlistData?.data?.is_public || true,
      });
    }
  }, [playlistData]);

  React.useEffect(() => {
    setSearchParams((prev) => ({
      ...prev,
      playlistID: playlistID,
    }));
  }, [playlistID]);

  React.useEffect(() => {
    if (open === false) {
      setSearchParams((prev) => ({
        ...prev,
        find: "",
      }));
    }
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box>
          <div className="d-flex justify-content-between mb-4">
            <h3 className="d-flex justify-content-start align-items-center">
              {playlistID ? "Edit" : "Create"} playlist {searchParams?.find}
            </h3>
            <h1 className="d-flex justify-content-end align-items-center">
              <div className="leaderboard-tabs">
                <Button
                  size="small"
                  type="submit"
                  variant="contained"
                  onClick={handleSubmit}
                  color="primary"
                >
                  {isLoading ? "Loading..." : playlistID ? "Update" : "Create"}
                </Button>
              </div>
              <span className="mx-2"></span>
              <CloseIcon style={{ cursor: "pointer" }} onClick={handleClose} />
            </h1>
          </div>

          <div className="row">
            <div className="col-12 col-lg-6">
              <div className="row">
                <div className="col-12">
                  <FormControl className="w-100">
                    <FormLabel className="text-start">Name</FormLabel>
                    <TextField
                      type="text"
                      id="name"
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                    />
                  </FormControl>
                </div>

                <div className="col-12 mt-4">
                  <FormControl className="w-100">
                    <FormLabel className="text-start">Genres</FormLabel>
                    <Select
                      id="genres"
                      name="genres"
                      multiple
                      value={formData.genres}
                      onChange={handleGenreChange}
                      renderValue={(selected) => (
                        <div>
                          {selected.map((value) => {
                            const genre = genresList?.find(
                              (genre) => genre.id === value
                            );
                            return (
                              genre && (
                                <Chip
                                  size="small"
                                  key={value}
                                  label={genre.name}
                                />
                              )
                            );
                          })}
                        </div>
                      )}
                    >
                      {genresList?.map((genre) => (
                        <MenuItem key={genre.id} value={genre.id}>
                          <Checkbox
                            checked={formData?.genres?.includes(genre.id)}
                          />
                          <ListItemText primary={genre.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>

                <div className="col-12 mt-4">
                  <FormControl className="w-100">
                    <FormLabel className="text-start">
                      Is playlist public ?
                    </FormLabel>
                    <Switch
                      name="is_public"
                      checked={formData?.is_public}
                      onChange={(event) =>
                        setFormData((prevFormData) => ({
                          ...prevFormData,
                          is_public: event.target.checked,
                        }))
                      }
                    />
                  </FormControl>
                </div>

                <div className="col-12 mt-4">
                  <FormControl className="w-100">
                    <FormLabel className="text-start">Movies</FormLabel>
                    <div className="d-flex flex-wrap w-100">
                      {formData?.movies?.map((movie) => {
                        return (
                          <>
                            <Chip
                              className="m-1"
                              label={movie?.title}
                              variant="outlined"
                              onDelete={() => handleToggleAddMovie(movie)}
                            />
                          </>
                        );
                      })}
                    </div>
                  </FormControl>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <TextField
                className="w-100"
                onChange={(event) => {
                  const enteredText = event.target.value;
                  if (enteredText.length > 1) {
                    setSearchParams((prevSearchParams) => ({
                      ...prevSearchParams,
                      find: enteredText,
                    }));
                  }

                  if (enteredText.length === 0) {
                    setSearchParams((prevSearchParams) => ({
                      ...prevSearchParams,
                      find: "",
                    }));
                  }
                }}
                label={`Search ${
                  location?.pathname === "/tv-shows" ? "TV Shows" : "Movies"
                }`}
                required
              />

              {isSearching && <span>Loading....</span>}

              {data?.results?.length !== 0 && <hr />}

              <div>
                {data?.results?.map((movie) => {
                  return (
                    <Box key={movie.movie_id}>
                      <div className="d-flex justify-content-between align-items-center text-start mb-2">
                        <div className="d-flex align-items-center">
                          <img
                            src={movie.thumbnail || placeholderPoster}
                            alt=""
                            className="search-poster-image"
                          />
                          <div className="px-2">
                            <Typography className="m-0 p-0" variant="p">
                              {movie.original_name || movie.title}
                            </Typography>
                            <Typography
                              className="text-muted m-0 p-0"
                              variant="caption"
                            >
                              {movie.overview && (
                                <p>
                                  {movie.overview.length > 200
                                    ? `${movie.overview.substring(0, 200)}...`
                                    : movie.overview}
                                </p>
                              )}
                            </Typography>
                          </div>
                        </div>
                        <div
                          className="d-flex justify-content-end"
                          style={{ width: "250px" }}
                        >
                          <Button
                            size="small"
                            color={
                              isMovieDataExists(movie) ? "error" : "success"
                            }
                            variant={
                              !isMovieDataExists(movie)
                                ? "outlined"
                                : "contained"
                            }
                            onClick={() => handleToggleAddMovie(movie)}
                          >
                            {/* <UilPlus /> */}
                            {isMovieDataExists(movie) ? "Remove" : "Add"}
                          </Button>
                        </div>
                      </div>
                    </Box>
                  );
                })}
              </div>
            </div>
          </div>
        </Box>
      </Box>
    </Modal>
  );
};

export default CreateModal;
