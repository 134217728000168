import React from 'react'
import {Link} from 'react-router-dom'
import { useNavigate } from "react-router-dom";
import { UilMultiply } from "@iconscout/react-unicons";
import PlaylistsTable from "../common/playlistsTable";


export default function Index() {
  const navigate = useNavigate();

  return (
    <>
      <section className="leaderboard-section">
        <div className="d-flex justify-content-between container align-items-center">
          <h1 className="text-light text-center py-4">Playlists</h1>
          <div className="d-flex align-items-center">
            <Link onClick={() => navigate(-1)}>
              <UilMultiply />
            </Link>
          </div>
        </div>

        <PlaylistsTable is_create={true} />
      </section>
    </>
  );
}

