import React, { createContext, useState, useContext, useEffect } from "react";

// Create the context
const LightModeContext = createContext();

// Create a provider component
export const LightModeProvider = ({ children }) => {
  const [isLightMode, setIsLightMode] = useState(false);

  // Load mode from localStorage on component mount
  useEffect(() => {
    const savedMode = localStorage.getItem("isLightMode");
    if (savedMode === "true") {
      setIsLightMode(true);
      document.body.classList.add("light");
    }
  }, []);

  const toggleLightMode = () => {
    const newMode = !isLightMode;
    setIsLightMode(newMode);
    localStorage.setItem("isLightMode", newMode);
    if (newMode) {
      document.body.classList.add("light");
    } else {
      document.body.classList.remove("light");
    }
  };

  return (
    <LightModeContext.Provider value={{ isLightMode, toggleLightMode }}>
      {children}
    </LightModeContext.Provider>
  );
};

// Custom hook to use the LightModeContext
export const useLightMode = () => useContext(LightModeContext);
