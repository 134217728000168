import * as React from "react";
import { UilMultiply } from "@iconscout/react-unicons";
import {Link} from 'react-router-dom'
import {  TextField } from "@mui/material";
import { useUsersList } from "../../hooks/apis/Auth";
import NoData from "../common/noData";
import {  useNavigate } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import LevelBadge from '../parts/levelBadge'


export default function Index() {
  const navigate = useNavigate();
  const [find, setFind] = React.useState('');
  const { isLoading: isUsersListLoading, data: usersList } = useUsersList({
    find: find,
  });

  const handleFindChange = (event) => {
    setFind(event.target.value);
  };


  return (
    <>
      <section className="leaderboard-section">
        <div className="d-flex justify-content-between container align-items-center">
          <h1 className="text-light text-center py-4">Audience</h1>
          <Link onClick={() => navigate(-1)}>
            <UilMultiply />
          </Link>
        </div>

        <div className="container">
          <div className="leader-board-filter d-flex justify-content-start mb-3">
            <TextField
              className="custom_field"
              size={"large"}
              label="Search by username ..."
              variant="outlined"
              value={find}
              sx={{width:'300px'}}
              onChange={handleFindChange}
            />
          </div>

          <div className="leaderboard-table mb-4">
            {usersList?.results?.map((user, idx) => (
              <>
                <div key={user.id} onClick={() => navigate(`/audience/${user?.username}/${user?.id}`)}>
                  <div className="leaderboard-row d-flex">
                    <div className="leaderboard-cell id-cell leader-cell">
                      {idx + 1}
                    </div>
                    <div className="leaderboard-cell movie-cell leader-cell py-2">
                      <Avatar
                        className="mx-2"
                        sx={{ width: 56, height: 56 }}
                        alt={user.name}
                        src={user.avatar}
                      />
                      <div>
                        <h6 className="m-0 p-0 leader-h5">{user.username}</h6>
                        <p className="m-0 p-0">{user.country?.name}</p>
                      </div>
                    </div>
                    <div className="leaderboard-cell score-cell leader-cell">
                      <LevelBadge user={user} hideToolTip={true} />
                    </div>
                  </div>
                </div>
              </>
            ))}

            {!usersList?.count && <NoData />}
          </div>
        </div>
      </section>
    </>
  );
}
