import LeaderboardTable from '../common/leaderboardTable';
import {
  useProfile,
  useUpdateProfile,
  useCountries,
  useChangePassword,
} from "../../hooks/apis/Auth";
import { TextField, Button, CircularProgress } from "@mui/material";
import AvatarImage from '../../assets/images/avatar.png'
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import Box from "@mui/material/Box";
import FavoriteIcon from "@mui/icons-material/Favorite";
import CloseIcon from "@mui/icons-material/Close";

import * as React from "react";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  useResetLeaderboard,
  useResetHiddenMovies,
} from "../../hooks/apis/movies";
import ColorMode from "../parts/colorMode";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#fff",
  border: "2px solid #eee",
  borderRadius: "10px",
  boxShadow: 24,
  pt: 4,
  px: 4,
  pb: 4,
  width: "40%",
  textAlign: "center",
  maxHeight: "80vh",
  overflow: "auto",
};


export default function Index() {

  const [isChangePasswordModalopen, setChangePasswordModalopen] = React.useState(false);
  const handleChangePasswordModalOpen = () => setChangePasswordModalopen(true);
  const handleChangePasswordModalClose = () => setChangePasswordModalopen(false);

  const { isLoading: isProfileLoading, data: profile } = useProfile();
  const { mutate, isLoading } = useUpdateProfile();
  const { mutate:changepasswordMutate, isLoading:changingpassword } = useChangePassword();
  const { isFetching, data:countries, refetch } = useCountries();
  const { mutate: resetLeaderboardMutate, isLoading: resetingLeaderboard } = useResetLeaderboard();
  const { mutate: resetHiddenMovies } = useResetHiddenMovies();

  const [formData, setFormData] = React.useState({
    username:"",
    name: "",
    bio: "",
    avatar: null, // Initialize "avatar" as null
    new_password: null,
    current_password: null,
  });

  const [isEdit, setIsEdit] = React.useState(true);
  const GENDER_CHOICES = [
    { value: "1", label: "Male" },
    { value: "2", label: "Female" },
    { value: "3", label: "Other" },
  ];



  React.useEffect(() => {
    // Populate the form data with the fetched profile when it becomes available
    if (!isProfileLoading && profile) {
      setFormData({
        name: profile.name || "", // Provide default values or empty strings if not available
        bio: profile.bio || "",
        date_of_birth: profile.date_of_birth || null,
        country: profile.country.code || null,
        gender: profile.gender || null,
        avatar: null,
        username: profile.username,
      });
    }
  }, [isProfileLoading, profile]);


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData({
      ...formData,
      avatar: file,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    mutate(formData)
  };

  const handleSubmitChangePassword = (e) => {
    e.preventDefault();
    changepasswordMutate(formData);
  };



  return (
    <>
      <section className="home-section p-4">
        <div className="row w-100">
          {/* Left Side */}
          <div className="col-2"></div>
          <div className="col-8 friends-col">
            <form onSubmit={handleSubmit}>
              <div className="row h-100">
                <div className="col-12">
                  <Box
                    sx={{ "& button": { ml: 2, mb: 2 } }}
                    className="d-flex profile-btn justify-content-between align-items-center px-3"
                  >
                    <div className="d-flex align-items-center">
                      <div className="profile-avatar-card">
                        {isEdit && (
                          <input
                            type="file"
                            id="avatar"
                            name="avatar"
                            accept="image/*"
                            style={{ display: "none" }} // Hide the input
                            onChange={handleFileChange}
                          />
                        )}
                        <label
                          htmlFor="avatar"
                          className="avatar-image-profile"
                        >
                          <img
                            className="avatar-image-profile"
                            src={
                              formData.avatar
                                ? URL.createObjectURL(formData.avatar)
                                : profile?.avatar || AvatarImage
                            }
                            alt="Avatar"
                            style={{ cursor: "pointer" }}
                          />
                        </label>
                      </div>
                      <span className="mx-2"></span>
                      <h3 className="text-light m-0 p-0">
                        {isEdit ? "Edit Profile" : "Welcome"}
                      </h3>
                    </div>
                    <div>
                      <Button
                        className="my-0 ml-2"
                        onClick={() => resetHiddenMovies()}
                        variant="outlined"
                        size="medium"
                      >
                        Reset Hidden Movies
                      </Button>

                      <Button
                        className="my-0 ml-2"
                        onClick={() => resetLeaderboardMutate()}
                        variant="outlined"
                        size="medium"
                      >
                        Reset Leaderboard
                      </Button>

                      <Button
                        className="my-0 ml-2"
                        onClick={handleChangePasswordModalOpen}
                        variant="outlined"
                        size="medium"
                        // startIcon={<DriveFileRenameOutlineIcon />}
                      >
                        Change Password
                      </Button>
                    </div>
                  </Box>
                </div>
                <div className="col-12 mt-4">
                  <div className="row w-100 h-100">
                    <div className="col-12">
                      <div className="h-100 home-btns-div pt-0">
                        <div className="profile-user-name-card py-3">
                          <div className="row">
                            <div className="col-lg-6 col-12">
                              <label className="w-100 text-light mb-2">
                                Name
                              </label>
                              <input
                                className="form-select w-100 profile-edit-input"
                                type="text"
                                id="name"
                                name="name"
                                value={formData.name}
                                onChange={handleInputChange}
                              />
                            </div>

                            <div className="col-lg-6 col-12">
                              <label className="w-100 text-light mb-2">
                                Username
                              </label>
                              <input
                                className="form-select w-100 profile-edit-input"
                                type="text"
                                id="username"
                                name="username"
                                value={formData.username}
                                onChange={handleInputChange}
                              />
                            </div>

                            <div className="col-lg-6 col-12">
                              <label className="w-100 text-light mb-2">
                                Date of birth
                              </label>
                              <input
                                className="form-select w-100 profile-edit-input"
                                type="date"
                                id="name"
                                name="date_of_birth"
                                value={formData.date_of_birth}
                                onChange={handleInputChange}
                              />{" "}
                            </div>

                            <div className="col-lg-6 col-12">
                              <label className="w-100 text-light mb-2">
                                Country
                              </label>
                              <select
                                className="form-select w-100 profile-edit-input"
                                id="country-select"
                                name="country"
                                value={formData?.country}
                                onChange={handleInputChange}
                              >
                                <option value="">Select a country</option>
                                {countries?.map((country) => (
                                  <option
                                    key={country.code}
                                    value={country.code}
                                  >
                                    {country.name}
                                  </option>
                                ))}
                              </select>
                            </div>

                            <div className="col-lg-6 col-12">
                              <label className="w-100 text-light mb-2">
                                Gender
                              </label>
                              <select
                                className="form-select w-100 profile-edit-input mb-4"
                                id="gender-select"
                                name="gender"
                                value={formData?.gender}
                                onChange={handleInputChange}
                              >
                                <option value="">Select gender</option>
                                {GENDER_CHOICES?.map((option) => (
                                  <option
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </option>
                                ))}
                              </select>
                            </div>

                            <div className="col-lg-6 col-12 d-flex align-items-center">
                              <label className="w-100 text-light mb-2">
                                Theme
                              </label>
                              <ColorMode />
                            </div>

                            <div className="col-12">
                              <label className="w-100 text-light mb-2">
                                Bio
                              </label>
                              <textarea
                                className="w-100 profile-edit-input"
                                fullWidth
                                label="Bio"
                                name="bio"
                                value={formData.bio}
                                onChange={handleInputChange}
                                variant="outlined"
                                margin="normal"
                                multiline // Enable multiline input
                                rows={3} // Set the number of rows (adjust as needed)
                              />
                            </div>

                            <div className="col-12">
                              <div className="profile-btn2">
                                <Button
                                  className="w-100 py-3"
                                  type="submit"
                                  variant="contained"
                                  color="primary"
                                >
                                  {isLoading ? "Updating..." : "Update Profile"}
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="col-2"></div>
        </div>
      </section>

      <Modal
        open={isChangePasswordModalopen}
        onClose={handleChangePasswordModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="d-flex justify-content-between">
            <h3 className="d-flex justify-content-start mb-4">
              Change Password
            </h3>
            <h1 className="d-flex justify-content-end mb-4">
              <CloseIcon
                style={{ cursor: "pointer" }}
                onClick={handleChangePasswordModalClose}
              />
            </h1>
          </div>

          <form onSubmit={handleSubmitChangePassword}>
            <input
              className="form-select w-100 profile-edit-input"
              type="text"
              id="current_password"
              name="current_password"
              value={formData.current_password}
              placeholder="Current Password"
              onChange={handleInputChange}
            />
            <input
              className="form-select w-100 profile-edit-input"
              type="text"
              id="new_password"
              name="new_password"
              value={formData.new_password}
              placeholder="New Password"
              onChange={handleInputChange}
            />
            <div className="profile-btn2">
              <Button type="submit" variant="outlined" size="medium">
                Change Password
              </Button>
            </div>
          </form>
        </Box>
      </Modal>
    </>
  );
}

