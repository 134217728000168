import { UilEstate } from "@iconscout/react-unicons";
import { UilFilm } from "@iconscout/react-unicons";
import { UilTvRetro } from "@iconscout/react-unicons";
import { UilUser } from "@iconscout/react-unicons";
import Button from "@mui/material/Button";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Logo from './logo'
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import * as React from "react";
import WatchListModal from '../profile/WatchListModal'
import DashboardIcon from "@mui/icons-material/Dashboard";
import TheatersIcon from "@mui/icons-material/Theaters";
import Tooltip from "@mui/joy/Tooltip";
import LevelBadge from './levelBadge'
import { useAuth } from "../../contexts/AuthContext";
import QueueIcon from "@mui/icons-material/Queue";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";


function Nav() {
  const { isLoggedIn, user, logout } = useAuth();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [openWatchList, setOpenWatchList] = React.useState(false);
  const handleOpenWatchList = () => setOpenWatchList(true);
  const handleCloseWatchList = () => setOpenWatchList(false);


  return (
    <>
      <div className="nav-row top-nav text-light">
        <div className="custom-container">
          <NavLink to="/">
            <Button style={{ width: "200px" }}>
              <UilEstate className="text-light" />
            </Button>
          </NavLink>

          <NavLink to="/movies">
            <Button>
              <UilFilm className="text-light" />
            </Button>
          </NavLink>

          <NavLink to="/" className="h-100 d-flex align-items-center">
            <Logo className="logo" />
          </NavLink>

          <NavLink to="/tv-shows">
            <Button>
              <UilTvRetro className="text-light" />
            </Button>
          </NavLink>

          <Button style={{ width: "200px" }}>
            {isLoggedIn && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <LevelBadge user={user} />

                  <Tooltip title="Account settings">
                    <IconButton
                      onClick={handleClick}
                      size="small"
                      sx={{ ml: 2 }}
                      aria-controls={open ? "account-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                    >
                      <Avatar sx={{ width: 38, height: 38 }}>
                        <UilUser />
                      </Avatar>
                    </IconButton>
                  </Tooltip>
                </Box>
                <Menu
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={open}
                  onClose={handleClose}
                  onClick={handleClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      navigate("/profile");
                    }}
                  >
                    <ListItemIcon>
                      <DashboardIcon fontSize="small" />
                    </ListItemIcon>
                    Dashboard
                  </MenuItem>

                  <MenuItem
                    onClick={() => {
                      handleClose();
                      navigate("/profile/leaderboard");
                    }}
                  >
                    <ListItemIcon>
                      <WorkspacePremiumIcon fontSize="small" />
                    </ListItemIcon>
                    Leaderboard
                  </MenuItem>

                  <MenuItem
                    onClick={() => {
                      handleClose();
                      navigate("/profile/playlists");
                    }}
                  >
                    <ListItemIcon>
                      <QueueIcon fontSize="small" />
                    </ListItemIcon>
                    Playlists
                  </MenuItem>

                  <MenuItem
                    onClick={() => {
                      handleClose();
                      handleOpenWatchList();
                    }}
                  >
                    <ListItemIcon>
                      <TheatersIcon fontSize="small" />
                    </ListItemIcon>
                    Watch List
                  </MenuItem>

                  <Divider />
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      navigate("/profile/edit");
                    }}
                  >
                    <ListItemIcon>
                      <Settings fontSize="small" />
                    </ListItemIcon>
                    Edit Profile
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      logout();
                      navigate("/login");
                    }}
                  >
                    <ListItemIcon>
                      <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                  </MenuItem>
                </Menu>
              </>
            )}
            {!isLoggedIn && (
              <UilUser
                className="text-light"
                onClick={() => {
                  !isLoggedIn ? navigate("/login") : navigate("/profile");
                }}
              />
            )}
          </Button>
        </div>
      </div>

      {isLoggedIn && (
        <WatchListModal
          open={openWatchList}
          handleOpen={handleOpenWatchList}
          handleClose={handleCloseWatchList}
        />
      )}
    </>
  );
}

export default Nav;
