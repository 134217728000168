import React from 'react'
import { useAuth } from "../../contexts/AuthContext";
import GuestMovies from './GuestMovies'
import UserMovies from './UserMovies'

const Index = ({ open, filterOpen, setFilterOpen }) => {
  const { isLoggedIn } = useAuth();

  return (
    <>
      {isLoggedIn ? (
        <UserMovies
          open={open}
          filterOpen={filterOpen}
          setFilterOpen={setFilterOpen}
        />
      ) : (
        <GuestMovies
          open={open}
          filterOpen={filterOpen}
          setFilterOpen={setFilterOpen}
        />
      )}
    </>
  );
};

export default Index