import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { UilFilter } from "@iconscout/react-unicons";
import {
  useSetUserFilter,
  useGetUserFilter,
  useGenresList,
  usePlaylists,
} from "../../hooks/apis/movies";
import CloseIcon from "@mui/icons-material/Close";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { getUser } from "../../hooks/apis/Auth";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import { useLocation } from "react-router-dom";
import Badge from "@mui/material/Badge";


import {
  FormControl,
  FormLabel,
  Checkbox,
  Button,
  ListItemText,
  Chip,
  Typography,
} from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#fff",
  border: "2px solid #eee",
  borderRadius: "10px",
  boxShadow: 24,
  pt: 4,
  px: 4,
  pb: 4,
  width: "50%",
  textAlign: "center",
  maxHeight: "80vh",
  overflow: "auto",
};

const statusList = [
  {
    id: "1",
    label: "All",
  },
  {
    id: "2",
    label: "Ranked",
  },
  {
    id: "3",
    label: "Unranked",
  },
];

const ratingList = [
  {
    id: "1",
    label: "All",
  },
  {
    id: "2",
    label: "Top 250",
  },
];



const cycleRankList = [
  {
    id: "0",
    label: "All",
  },
  {
    id: "1",
    label: "Top 10",
  },
  {
    id: "2",
    label: "Top 25",
  },
  {
    id: "3",
    label: "Top 100",
  },
  {
    id: "4",
    label: "Bottom 10",
  },
  {
    id: "5",
    label: "Bottom 25",
  },
  {
    id: "6",
    label: "Bottom 100",
  },
];

export default function BasicModal({ filterOpen, setFilterOpen }) {
  const location = useLocation();
  const user = getUser();
  const handleOpen = () => setFilterOpen(true);
  const handleClose = () => {
    setFilterOpen(false);
  };

  const [filter, setFilter] = React.useState({
    year_from: null,
    year_to: null,
    genres: [],
    playlists: [],
    companies: [],
    providers: [],
    status: "1",
    rating: "1",
    cycle_ranked_through: "0",
  });

  const { isLoading: isUserFilterLoading, data: userFilter } =
    useGetUserFilter();
  const { data: genresList } = useGenresList({
    type: location?.pathname == "/tv-shows" ? "tv-show" : "movie",
  });

  const { data: userPlaylists } = usePlaylists({
    type: location?.pathname == "/tv-shows" ? "tv-show" : "movie",
  });


  const { mutate, isLoading } = useSetUserFilter();

  React.useEffect(() => {
    if (user) {
      if (!isUserFilterLoading && userFilter) {
        setFilter({
          year_from: userFilter.year_from,
          year_to: userFilter.year_to,
          genres: userFilter.genres,
          playlists: userFilter.playlists,
          companies: userFilter.companies,
          providers: userFilter.providers,
          status: userFilter.status,
          plot: userFilter.plot,
          rating: userFilter.rating,
          cycle_ranked_through: userFilter.cycle_ranked_through,
          is_empty: userFilter.is_empty,
        });
      }
    }
  }, [isUserFilterLoading, userFilter]);

  const handleFormSubmit = (event) => {
    event.preventDefault();
    mutate(filter);
    handleClose();
  };


  const handleDateChange = (date, name) => {
    if (!date) {
      setFilter((prevFilter) => ({
        ...prevFilter,
        [name]: null,
      }));

      return;
    }

    if (name === "year_from") {
      date.startOf("year");
    } else if (name === "year_to") {
      date.endOf("year");
    }
      setFilter((prevFilter) => ({
        ...prevFilter,
        [name]: moment(date).format("YYYY-MM-DD"),
      }));
  };


  const handleGenreChange = (event) => {
    const { value } = event.target;
    setFilter((prevFilter) => ({
      ...prevFilter,
      genres: value,
    }));
  };

  const handlePlaylistsChange = (event) => {
    const { value } = event.target;
    setFilter((prevFilter) => ({
      ...prevFilter,
      playlists: value,
    }));
  };


  const handleStatusChange = (event) => {
    const { value } = event.target;
    setFilter((prevFilter) => ({
      ...prevFilter,
      status: value,
    }));
  };

  const handleRatingChange = (event) => {
    const { value } = event.target;
    setFilter((prevFilter) => ({
      ...prevFilter,
      rating: value,
    }));
  };

  const handleCycleRankChange = (event) => {
    const { value } = event.target;
    setFilter((prevFilter) => ({
      ...prevFilter,
      cycle_ranked_through: value,
    }));
  };

  const handleClearFilter = () => {
    mutate({
      year_from: null,
      year_to: null,
      playlists: [],
      genres: [],
      companies: [],
      providers: [],
      plot: null,
      status: "1", //All
      rating: "1", //All
      cycle_ranked_through: "0", //All
    });
    handleClose();
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleFormSubmit();
    }else if (event.key === "c") {handleClearFilter()}
  };

  return (
    <div>
      {user ? (
        <>
          <Button onClick={handleOpen}>
            <Badge color="error" variant="dot" invisible={userFilter?.is_empty}>
              <UilFilter className="text-light" />
            </Badge>
          </Button>

          <Modal
            open={filterOpen}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style} onKeyDown={handleKeyDown}>
              <div className="d-flex justify-content-between">
                <h3 className="d-flex justify-content-start mb-4">Filter</h3>
                <h1 className="d-flex justify-content-end mb-4">
                  <CloseIcon
                    style={{ cursor: "pointer" }}
                    onClick={handleClose}
                  />
                </h1>
              </div>
              <form onSubmit={handleFormSubmit}>
                <div className="row">
                  <div className="col-12">
                    <FormControl className="w-100">
                      <FormLabel className="text-start">Playlists</FormLabel>

                      <Select
                        id="playlists"
                        name="playlists"
                        multiple
                        value={filter?.playlists}
                        onChange={handlePlaylistsChange}
                        renderValue={(selected) => (
                          <div>
                            {selected.map((value) => {
                              const playlists = userPlaylists?.find(
                                (playlists) => playlists.id === value
                              );
                              return (
                                playlists && (
                                  <Chip key={value} size='small' label={playlists.name} />
                                )
                              );
                            })}
                          </div>
                        )}
                      >
                        {userPlaylists?.map((playlist) => (
                          <MenuItem key={playlist.id} value={playlist.id}>
                            <Checkbox
                              checked={filter?.playlists?.includes(playlist.id)}
                            />
                            <ListItemText primary={playlist.name} />
                          </MenuItem>
                        ))}
                      </Select>
                      <Typography variant="caption" className="text-start mb-4 mt-1">
                        {filter?.playlists?.length > 0 &&
                          "* Generated movies will be based on selected playlists, If leave playlists blank you can use custom filter below"}
                      </Typography>
                    </FormControl>
                  </div>

                  <div className="col-12 col-lg-6">
                    <FormControl className="w-100">
                      <FormLabel className="text-start">Year from</FormLabel>
                      <DatePicker
                        disabled={filter?.playlists?.length > 0}
                        views={["year"]}
                        value={
                          filter.year_from ? moment(filter.year_from) : null
                        }
                        onChange={(date) => handleDateChange(date, "year_from")}
                        inputVariant="outlined"
                        maxDate={moment(new Date(), "yyyy-MM-dd")}
                      />
                    </FormControl>
                  </div>

                  <div className="col-12 col-lg-6">
                    <FormControl className="w-100">
                      <FormLabel className="text-start">Year to</FormLabel>
                      <DatePicker
                        disabled={filter?.playlists?.length > 0}
                        views={["year"]}
                        value={filter.year_to ? moment(filter.year_to) : null}
                        onChange={(date) => handleDateChange(date, "year_to")}
                        inputVariant="outlined"
                        maxDate={moment(new Date(), "yyyy-MM-dd")}
                      />
                    </FormControl>
                  </div>
                </div>
                <div className="row my-lg-4">
                  <div className="col-12 col-lg-6">
                    <FormControl className="w-100">
                      <FormLabel className="text-start">Genres</FormLabel>
                      <Select
                        disabled={filter?.playlists?.length > 0}
                        id="genres"
                        name="genres"
                        multiple
                        value={filter.genres}
                        onChange={handleGenreChange}
                        renderValue={(selected) => (
                          <div>
                            {selected.map((value) => {
                              const genre = genresList?.find(
                                (genre) => genre.id === value
                              );
                              return (
                                genre && <Chip key={value} size='small' label={genre.name} />
                              );
                            })}
                          </div>
                        )}
                      >
                        {genresList?.map((genre) => (
                          <MenuItem key={genre.id} value={genre.id}>
                            <Checkbox
                              checked={filter?.genres?.includes(genre.id)}
                            />
                            <ListItemText primary={genre.name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>

                  <div className="col-12 col-lg-6">
                    <FormControl className="w-100">
                      <FormLabel className="text-start">Rating</FormLabel>
                      <Select
                        disabled={filter?.playlists?.length > 0}
                        id="rating"
                        name="rating"
                        value={filter.rating}
                        onChange={handleRatingChange}
                      >
                        {ratingList?.map((rating) => (
                          <MenuItem
                            className="text-start"
                            key={rating.id}
                            value={rating.id}
                          >
                            {rating.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>

                  {/* <div className="col-12 col-lg-6">
                    <FormControl className="w-100">
                      <FormLabel className="text-start">
                        Keywords
                        <Tooltip
                          describeChild
                          title="Can be a comma (AND) or pipe (OR) separated query"
                        >
                          <InfoIcon className="mx-1" sx={{ fontSize: 18 }} />
                        </Tooltip>
                      </FormLabel>
                      <TextField
                        views={["year"]}
                        value={filter?.plot}
                        name="plot"
                        onChange={handleInputChange}
                        inputVariant="outlined"
                      />
                    </FormControl>
                  </div> */}

                  {/* <div className="col-12 col-lg-6">
                    <FormControl className="w-100">
                      <FormLabel className="text-start">Companies</FormLabel>
                      <Select
                        id="companies"
                        name="companies"
                        multiple
                        value={filter.companies}
                        onChange={handleCompanyChange}
                        renderValue={(selected) => (
                          <div>
                            {selected.map((value) => {
                              const company = companiesList?.find(
                                (company) => company.id === value
                              );
                              return (
                                company && (
                                  <Chip key={value} label={company.name} />
                                )
                              );
                            })}
                          </div>
                        )}
                      >
                        {companiesList?.map((company) => (
                          <MenuItem key={company.id} value={company.id}>
                            <Checkbox
                              checked={filter?.companies?.includes(company.id)}
                            />
                            <ListItemText primary={company.name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div> */}

                  {/* 

                  <div className="col-12 col-lg-6">
                    <FormControl className="w-100">
                      <FormLabel className="text-start">
                        Watch Providers
                      </FormLabel>
                      <Select
                        id="providers"
                        name="providers"
                        multiple
                        value={filter.providers}
                        onChange={handleProviderChange}
                        renderValue={(selected) => (
                          <div>
                            {selected.map((value) => {
                              const provider = providersList?.find(
                                (provider) => provider.id === value
                              );
                              return (
                                provider && (
                                  <Chip key={value} label={provider.name} />
                                )
                              );
                            })}
                          </div>
                        )}
                      >
                        {providersList?.map((provider) => (
                          <MenuItem key={provider.id} value={provider.id}>
                            <Checkbox
                              checked={filter?.providers?.includes(provider.id)}
                            />
                            <ListItemText primary={provider.name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>

                  */}

                  {/* <div className="col-12 col-lg-6">
                                      <FormControl className="w-100">
                                          <FormLabel className="text-start">
                                              Companies
                                          </FormLabel>
                                          <Select
                                              id="companies"
                                              name="companies"
                                              multiple
                                              value={filter?.companies}
                                              onChange={handleCompanyChange}
                                              renderValue={(selected) => (
                                                  <div>
                                                      {selected?.map(
                                                          (value) => (
                                                              <Chip
                                                                  key={value}
                                                                  label={
                                                                      companiesList?.find(
                                                                          (
                                                                              company
                                                                          ) =>
                                                                              company?.id ===
                                                                              value
                                                                      )?.name
                                                                  }
                                                              />
                                                          )
                                                      )}
                                                  </div>
                                              )}
                                          >
                                              {companiesList?.map((company) => (
                                                  <MenuItem
                                                      key={company?.id}
                                                      value={company?.id}
                                                  >
                                                      <Checkbox
                                                          checked={filter?.companies?.includes(
                                                              company?.id
                                                          )}
                                                      />
                                                      <ListItemText
                                                          primary={
                                                              company?.name
                                                          }
                                                      />
                                                  </MenuItem>
                                              ))}
                                          </Select>
                                      </FormControl>
                                  </div> */}
                </div>
                <div className="row my-lg-4">
                  <div className="col-12 col-lg-6">
                    <FormControl className="w-100">
                      <FormLabel className="text-start">
                        Ranked / Unranked
                      </FormLabel>
                      <Select
                        disabled={filter?.playlists?.length > 0}
                        id="status"
                        name="status"
                        value={filter.status}
                        onChange={handleStatusChange}
                      >
                        {statusList?.map((status) => (
                          <MenuItem
                            className="text-start"
                            key={status.id}
                            value={status.id}
                          >
                            {status.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="col-12 col-lg-6">
                    <FormControl className="w-100">
                      <FormLabel className="text-start">
                        Cycle ranked through
                      </FormLabel>
                      <Select
                        disabled={
                          (filter.status !== "2") |
                          (filter?.playlists?.length > 0)
                        }
                        id="cycle_ranked_through"
                        name="cycle_ranked_through"
                        value={filter.cycle_ranked_through}
                        onChange={handleCycleRankChange}
                      >
                        {cycleRankList?.map((cycle) => (
                          <MenuItem
                            className="text-start"
                            key={cycle.id}
                            value={cycle.id}
                          >
                            {cycle.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className="d-flex justify-content-start flex-row-reverse">
                  <Button
                    size="medium"
                    color="primary"
                    variant="contained"
                    type="submit"
                    disabled={isLoading}
                  >
                    {isLoading ? "Saving..." : "Save"}
                  </Button>
                  <div className="mx-1"></div>
                  <Button
                    disabled={filter?.is_empty}
                    size="medium"
                    color="primary"
                    variant="outlined"
                    onClick={handleClearFilter}
                  >
                    {isLoading ? "Saving..." : "Clear Filter (C)"}
                  </Button>
                </div>
              </form>{" "}
            </Box>
          </Modal>
        </>
      ) : (
        <>
          <Button disabled>
            <FilterAltIcon className="text-light" />
          </Button>
        </>
      )}
    </div>
  );
}
