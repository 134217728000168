import React from 'react'
import { Button } from "@mui/material";
import ShuffleIcon from "@mui/icons-material/Shuffle";
import VsImage from '../../assets/images/vs2.jpeg'

const Vs = ({ isFetching, refetch, presedKey, userFilter, clearingFilter }) => {
  return (
    <div className="vs-section">
      <div className="text-center">
        <img className="vs-image" src={VsImage} alt="" />
      </div>

      <Button
        size="medium"
        onClick={() => refetch()}
        color="primary"
        variant="contained"
        type="submit"
        className="skip-movies-btn"
      >
        {isFetching ? (
          "Generating..."
        ) : (
          <>
            Shuffle <ShuffleIcon />
          </>
        )}
      </Button>
      <div className="hot-keys text-center mt-5 small">
        <p className="m-0 p-0 text-uppercase">Hot Keys</p>
        <div className="text-start">
          <p
            className={`m-0 p-0 ${
              presedKey === "a" && "text-success"
            }`}
          >
            (A) => Select Left Movie
          </p>
          <p
            className={`m-0 p-0 ${
              presedKey === "d" && "text-success"
            }`}
          >
            (D) => Select Right Movie
          </p>
          <p
            className={`m-0 p-0 ${
              presedKey === "s" && "text-success"
            }`}
          >
            (Space or S) => Shuffle Movies
          </p>
          {userFilter && (
            <>
              <p
                className={`m-0 p-0 ${
                  presedKey === "f" && "text-success"
                }`}
              >
                (F) => Show Filter
              </p>

              <p
                className={`m-0 p-0 ${
                  (presedKey === "c" && clearingFilter && "text-success") ||
                  (!userFilter?.is_empty && "text-danger")
                }`}
              >
                (C) => Clear Filter
              </p>
            </>
          )}
        </div>
      </div>


    </div>
  );
};

export default Vs