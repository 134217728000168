import * as React from "react";
import { useState } from "react";
import Movie from "../common/movieCard";
import { use2RandomMovies } from "../../hooks/apis/movies";
import { useCalculateScoreGuest } from "../../hooks/apis/guest";
import { getLocalstorageWithExpiration } from "../../utils/index";
import { useLocation } from "react-router-dom";
import Vs from "./Vs";

export default function Index({ open, filterOpen, setFilterOpen }) {
  const [presedKey, setPresedKey] = useState(null);
  const {
    mutate: mutateScoreCalculat,
    data: calculatedScoreDataGuest,
    isLoading: isCalculatingScoreGuest,
  } = useCalculateScoreGuest();
  const leaderboardJSON = getLocalstorageWithExpiration("leaderboard");
  const leaderboard = leaderboardJSON ? JSON.parse(leaderboardJSON) : [];

  const searchJSON = getLocalstorageWithExpiration("selected_search");
  const GuestSearchData = searchJSON ? JSON.parse(searchJSON) : null;

  const location = useLocation();
  const path = location?.pathname;

  if (path === "/tv-shows") {
    var type = "tv-show";
  } else {
    var type = "movie";
  }

  const { isFetching, isLoading, data, refetch } = use2RandomMovies({
    type: type,
  });

  const handleKeyDown = (event) => {
    console.log(event.key);
    if (event.key === "a") {
      handleSelectWinner(data?.movie_A, data?.movie_B, "slot_a");
      setPresedKey("a");
    } else if (event.key === "d") {
      handleSelectWinner(data?.movie_B, data?.movie_A, "slot_b");
      setPresedKey("d");
    } else if (event.key === " " || event.key === "s") {
      refetch();
      setPresedKey("s");
    }
  };

  React.useEffect(() => {
    refetch();
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleSelectWinner = (winner, loser, slot) => {
    winner["type"] = type;
    winner["slot"] = slot;
    loser["type"] = type;

    let data = {
      winner: winner,
      loser: loser,
      type: type,
    };
    mutateScoreCalculat(data);
  };

  return (
    <>
      <section className="home-section" onKeyDown={handleKeyDown}>
        <div className="ad" style={{ width: "15%" }}>
          <div
            className="text-primary d-flex justify-content-center align-items-center  text-dark"
            style={{ width: "90%", height: "95%" }}
          ></div>
        </div>

        <div className="movies-row">
          <Movie
            slot="slot_a"
            movie={
              (GuestSearchData?.type == type && GuestSearchData) ||
              calculatedScoreDataGuest?.movie_A ||
              data?.movie_A ||
              null
            }
            loser={calculatedScoreDataGuest?.movie_B || data?.movie_B || null}
            handleSelectWinner={handleSelectWinner}
            isLoading={isLoading}
            isFetching={isFetching}
            isCalculatingScoreGuest={isCalculatingScoreGuest}
            refetch2RandomMovies={refetch}
          />
          <Vs
            isFetching={isFetching}
            refetch={refetch}
            presedKey={presedKey}
          />
          <Movie
            slot="slot_b"
            movie={calculatedScoreDataGuest?.movie_B || data?.movie_B || null}
            loser={calculatedScoreDataGuest?.movie_A || data?.movie_A || null}
            handleSelectWinner={handleSelectWinner}
            isLoading={isLoading}
            isFetching={isFetching}
            isCalculatingScoreGuest={isCalculatingScoreGuest}
            refetch2RandomMovies={refetch}
          />
        </div>
        <div className="ad" style={{ width: "15%" }}>
          <div
            className="text-primary d-flex justify-content-center align-items-center  text-dark"
            style={{ width: "90%", height: "95%" }}
          ></div>
        </div>
      </section>
    </>
  );
}
