import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useSignup,useCountries } from "../../hooks/apis/Auth";
import { useAuth } from "../../contexts/AuthContext";
import '../login/style.css'


export default function RegisterPage() {
  const navigate = useNavigate();
  const { isLoggedIn } = useAuth();
  const [formData, setFormData] = useState({name: "" ,email: "", password: "", gender:"1" });
  const { mutate, isLoading } = useSignup();
  const { isFetching, data, refetch } = useCountries();



  useEffect(() => {
    if (isLoggedIn) {
      navigate("/"); // Redirect to homepage if already logged in
    }
  }, [isLoggedIn, navigate]);



  const GENDER_CHOICES = [
    { value: "1", label: "Male" },
    { value: "2", label: "Female" },
    { value: "3", label: "Other" },
  ];


  const handleSubmit = async (event) => {
    event.preventDefault();
    mutate(formData, {
      onSuccess: () => {
        // Navigate to the previous page or dashboard after login
        navigate('/login');
      },
    });
  };

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <section id="login-page">
      <div className="login-page-overlay"></div>
      <div className="login signup">
        <form onSubmit={handleSubmit}>
          <div className="form">
            <h2>Create an account</h2>

            <div className="row">
              <div className="col-12 col-lg-6">
                <div className="form-field">
                  <input
                    id="name"
                    type="text"
                    name="name"
                    placeholder="Name"
                    value={formData.name}
                    onChange={handleChange}
                    required="true"
                  />
                </div>
                <div className="form-field">
                  <input
                    id="username"
                    type="text"
                    name="username"
                    placeholder="Username"
                    value={formData.username}
                    onChange={handleChange}
                    required="false"
                  />
                </div>

                <div className="form-field">
                  <input
                    id="login-mail"
                    type="text"
                    name="email"
                    placeholder="Email Address"
                    value={formData.email}
                    onChange={handleChange}
                    required="true"
                  />
                </div>

                <div className="form-field">
                  <input
                    id="login-password"
                    type="password"
                    name="password"
                    placeholder="Password"
                    value={formData.password}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="form-field">
                  <input
                    className="form-select w-100 country_select"
                    id="gender-select"
                    name="date_of_birth"
                    type="date"
                    value={formData.date_of_birth}
                    onChange={handleChange}
                  ></input>
                </div>

                <div className="form-field">
                  <select
                    className="form-select w-100 country_select"
                    id="country-select"
                    name="country"
                    value={formData.country}
                    onChange={handleChange}
                  >
                    <option value="">Select a country</option>
                    {data?.map((country) => (
                      <option key={country.code} value={country.name}>
                        {country.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="form-field">
                  <select
                    className="form-select w-100 country_select"
                    id="gender-select"
                    name="gender"
                    value={formData.gender}
                    onChange={handleChange}
                  >
                    <option>Select gender</option>
                    {GENDER_CHOICES?.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            <button type="submit" className="button">
              <div className="arrow-wrapper">
                <span className="arrow" />
              </div>
              <button
                type="submit"
                disabled={isLoading}
                className="button-text"
              >
                {isLoading ? "Loading..." : "Sign up"}
              </button>
            </button>

            <p className="mb-5 d-flex justify-content-center">
              <span className="text-muted">Already have an account?</span>{" "}
              <Link to="/login">Login</Link>
            </p>
          </div>
        </form>
      </div>
    </section>
  );
}
