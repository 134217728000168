import React from 'react'
import { useParams } from "react-router-dom";
import LeaderboardTable from '../common/leaderboardTable'
import { Link } from "react-router-dom";
import { UilMultiply } from "@iconscout/react-unicons";
import { useNavigate } from "react-router-dom";
import {useSingleUser} from "../../hooks/apis/Auth";
import PlaylistsTable from "../common/playlistsTable";
import Avatar from "@mui/material/Avatar";
import LevelBadge from "../parts/levelBadge";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}


const Detail = () => {
    const [value, setValue] = React.useState(0);
    const navigate = useNavigate();
    const { username , id} = useParams();
    const { data: user } = useSingleUser({id:id});
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

  return (
    <section className="leaderboard-section">
      <div className="d-flex justify-content-between container align-items-center">
        <div>
          {/* <h1 className="text-light text-center py-4">Leaderboard</h1> */}

          <div className="d-flex align-items-center py-4">
            <Avatar
              sx={{ width: 56, height: 56 }}
              alt={user?.name}
              src={user?.avatar}
            />
            <span className="mx-2"></span>
            <div>
              <h6 className="m-0 p-0 mb-1 leader-h5 text-light">
                {user?.username} | Leaderboard
              </h6>
              <LevelBadge user={user && user} hideToolTip={true} />
            </div>
          </div>
        </div>

        <div className="d-flex align-items-center justify-content-end">
          <Tabs
            sx={{
              "& .MuiTabs-indicator": {
                display: "none", // Hides the underline
              },
            }}
            value={value}
            onChange={handleChange}
            className="leaderboard-tabs audience-tabs w-100 justify-content-center"
          >
            <Tab label="Leaderboard" {...a11yProps(0)} />
            <Tab label="Playlists" {...a11yProps(1)} />
          </Tabs>
          <span className="mx-4"></span>
          <Link onClick={() => navigate(-1)}>
            <UilMultiply />
          </Link>
        </div>
      </div>
      <div className="container">
        <CustomTabPanel value={value} index={0}>
          <LeaderboardTable username={username} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <PlaylistsTable username={username} />
        </CustomTabPanel>
      </div>
    </section>
  );
}

export default Detail