import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
} from "react-query";
import { toast } from "react-toastify";
import api from "../axios";
import raiseScoreGainAndLoss from "../../../components/common/raiseScoreGainAndLoss";
import {getLocalstorageWithExpiration , setLocalstorageWithExpiration} from "../../../utils";



export const handleUpdateOrAdd = (movieToAddOrUpdate) => { 
     // Retrieve the existing leaderboard array from localStorage
     const leaderboardJSON = getLocalstorageWithExpiration("leaderboard");
     const leaderboard = leaderboardJSON ? JSON.parse(leaderboardJSON) : [];

     // Find the index of the movie with the matching movie_id
     const index = leaderboard.findIndex(
       (movie) => movie.movie_id === movieToAddOrUpdate.movie_id
     );

     if (index !== -1) {
       // Movie exists, update the object at the found index
       leaderboard[index] = movieToAddOrUpdate;
     } else {
       // Movie doesn't exist, add it to the array
       leaderboard.push(movieToAddOrUpdate);
     }


    // localStorage.setItem("selected_search", { movie_A: movieToAddOrUpdate });
    if (movieToAddOrUpdate.is_search_result){
     const x = getLocalstorageWithExpiration("selected_search");
     const search_json = x ? JSON.parse(x) : {"movie_A":null,"movie_B":null};
      
     search_json.movie_A = movieToAddOrUpdate;

      setLocalstorageWithExpiration(
          "selected_search",
          JSON.stringify(search_json)
      );
    }

      setLocalstorageWithExpiration("leaderboard", JSON.stringify(leaderboard));

   };



export function useCalculateScoreGuest() {
  const queryClient = useQueryClient();
  const {
    mutate,
    data,
    isLoading,
  } = useMutation(
    async (data) => {
      const response = await api.post("/api/movies/calculate-score-guest/", data);
      return response.data;
    },
    {
      onSuccess: (data) => {
        handleUpdateOrAdd(data["movie_A"]);

        // Retrieve the existing leaderboard array from localStorage
        const leaderboardJSON = getLocalstorageWithExpiration("leaderboard");
        const leaderboard = leaderboardJSON ? JSON.parse(leaderboardJSON) : [];

        // Find the index of the movie with the matching movie_id
        const index = leaderboard.findIndex(
            (movie) => movie.movie_id === data["movie_B"]['movie_id']
        );
        if (index !== -1) {
            // Movie exists, update the object at the found index
            handleUpdateOrAdd(data["movie_B"]);
        }
        raiseScoreGainAndLoss(data);
        
        setTimeout(() => {
          mutate(null);
          data["movie_A"].color=null;
          data["movie_B"].color=null;
          // handleUpdateOrAdd(data['movie_A']);
          // handleUpdateOrAdd(data["movie_B"]);
          queryClient.refetchQueries("RandomMovies");
        }, 5000);
      },
      onError: (error) => {
        // toast.error("Something went wrong");
      },
    }
  );

  return { mutate, data, isLoading };
}
