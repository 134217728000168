import React from 'react'
import LeaderboardGuest from './LeaderboardGuest'
import LeaderboardUser from "./LeaderboardUser";
import { useAuth } from "../../contexts/AuthContext";


const Index = () => {
  const { isLoggedIn } = useAuth();
  return <>{isLoggedIn ? <LeaderboardUser /> : <LeaderboardGuest />}</>;
}

export default Index