import {
  useProfile,
} from "../../hooks/apis/Auth";
import { Button, CircularProgress } from "@mui/material";
import AvatarImage from '../../assets/images/avatar.png'
import * as React from "react";
import MoviesSwiper from './moviesSwiper'
import NoData from "./noData";
import { useNavigate } from "react-router-dom";


export default function Index() {
  const { isLoading: isProfileLoading, data: profile } = useProfile();
  const navigate = useNavigate();

  return (
    <>
      <section className="home-section p-4">
        <div className="row w-100">
          <div className="col-3 h-100 overflow-hidden">
            <div className="profile-user-name-card d-flex justify-content-center align-items-center flex-column text-faded">
              {!isProfileLoading ? (
                <>
                  <img
                    className="avatar-image-profile mb-3"
                    src={profile?.avatar || AvatarImage}
                    alt="Avatar"
                  />
                  <h4 className="m-0 p-0">{profile?.name}</h4>
                  <p className="m-0 p-0 mb-3">{profile?.date_of_birth}</p>
                  <div className="profile-btn">
                    <Button
                      variant="outlined"
                      size="medium"
                      onClick={() => navigate("/profile/edit")}
                    >
                      Edit Profile
                    </Button>
                  </div>
                </>
              ) : (
                <CircularProgress />
              )}
            </div>
          </div>

          <div className="col-9 h-100 overflow-hidden">
            <div className="profile-user-name-card d-flex justify-content-center flex-column text-faded">
              {!isProfileLoading ? (
                <div className="row gx-5 w-100">
                  <div className="col-3">
                    <div className="d-flex align-items-center justify-content-between py-4">
                      <h5 className="text-primary p-0 m-0">Top 3 movies</h5>
                      <p
                        className="p-0 m-0 link"
                        onClick={() =>
                          navigate("/leaderboard", { state: "movie" })
                        }
                      >
                        See All
                      </p>
                    </div>

                    <MoviesSwiper list={profile?.top_3_movies} />
                  </div>
                  <div className="col-3">
                    <div className="d-flex align-items-center justify-content-between py-4">
                      <h5 className="text-primary p-0 m-0">Top 3 Tv</h5>
                      <p
                        className="p-0 m-0 link"
                        onClick={() =>
                          navigate("/leaderboard", { state: "tv-show" })
                        }
                      >
                        See All
                      </p>
                    </div>

                    <MoviesSwiper list={profile?.top_3_tv} />
                  </div>
                  <div className="col-6">
                    <h5 className="text-primary py-4">Top Genres</h5>
                    {profile?.top_genres?.map((item) => (
                      <p className="d-flex justify-content-between top-genre-row">
                        <span className="d-flex align-items-center">
                          <span className="px-2">{item[0]}</span>
                        </span>

                        <span className="d-flex align-items-center">
                          <span className="px-2 primary-color">{item[1]}</span>
                          <span
                            className="primary-color"
                            style={{ fontSize: "smaller" }}
                          >
                            Movie(s)
                          </span>
                        </span>
                      </p>
                    ))}
                    {profile?.top_genres?.length == 0 && <NoData />}
                  </div>
                </div>
              ) : (
                <CircularProgress />
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

